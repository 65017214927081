@import "~styles/colors.scss";

$countBoxSize: 33px;

.CountBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $countBoxSize;
  height: $countBoxSize;
  background-color: $tuna;
  border-radius: $countBoxSize * 0.06;
  color: $turquoiseBlue;

  &.CountBox--greenText {
    color: $greenYellow2;
  }

  &.CountBox--grayText {
    color: $comet;
  }

  &.CountBox--highlighted {
    color: $greenYellow2;
    background-color: $easternBlue;
  }
}
