@import "../../styles/colors.scss";
@import "../../styles/fontFamilies.scss";
@import "../../styles/fontSizes.scss";

$progressBarHeight: 36px;

.ProgressBar {
  background-color: $charade2;
  height: $progressBarHeight;
  padding: 3px;
  position: relative;
  width: 100%;

  .ProgressBar-filler {
    height: 100%;
    background-color: #82a40c;
    animation: 3s ease-in-out;
  }

  .ProgressBar-text {
    @include quickSandMedium;
    color: $white;
    font-size: $fontSizeNormal;
    left: 50%;
    position: absolute;
    top: $progressBarHeight/4;
  }
}
