@import "../../../styles/colors.scss";
@import "../../../styles/fontFamilies.scss";
@import "../../../styles/fontSizes.scss";

.QuestionMaturityProgressBar {
  padding: 26px 0 0 0;
  .QuestionMaturityProgressBar-text {
    @include quickSandMedium;
    font-size: $fontSizeNormal;
    margin-bottom: 9px;
    text-align: left;
    color: $white;
  }
}
