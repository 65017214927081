@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/margins.scss';
@import '~styles/paddings.scss';

.AreaInput {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-top: 15px;
  margin-bottom: 20px;

  .AreaInput-input {
    background-color: #191924;
    border: solid 1px rgba(50, 50, 73, 0.42);
    color: $white;
    display: block;
    padding: $padding2;

    &:focus {
      outline-style: none;
    }
  }

  .AreaInput-textArea {
    min-height: 50px;
    max-height: 70px;
  }

  .AreaInput-buttons {
    margin-top: 15px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .AreaInput-actions {
      // margin-top: $margin1;

      .FormButton {
        float: right;
      }
    }

    .AreaInput-color {
      // border: solid 2px white;

      border-radius: 10%;
      // margin: 5px;
      padding: 5px;
      width: 30px;
      height: 30px;
      border: 0px;
      outline: none;
      &:focus {
        // border: solid 2px $l /seanit101BlueLight3;
        outline-offset: 2px;
        outline: solid 2px;
        outline-color: $leanit101BlueLight3;
        // .color-picker{
        //   // display:block;
        // }
      }
    }
    .color-picker {
      position: absolute;
      top: 360px;
      left: 11px;
      z-index: 9999;
    }
  }
}
