@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';

$countBoxSize: 33px;

.ObjectiveMaturityContainer {
  margin-top: $margin1;

  .ObjectiveMaturityContainer-grid {
    display: grid;
    grid-template-columns: auto $countBoxSize;
    margin-bottom: 40px;

    .ObjectiveMaturityContainer-textLeft {
      text-align: initial;

      .ObjectiveMaturityContainer-title {
        padding-bottom: 5px;
      }

      .ObjectiveMaturityContainer-textColor {
        color: #5c5c6f;

        span {
          color: $turquoiseBlue;
        }
      }
    }
  }
}

.ObjectiveMaturityRow {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: $countBoxSize 1fr 2fr;
  margin-bottom: 8px;

  p {
    text-align: left;
    margin-top: calc(#{$countBoxSize} / 4);
    width: 130px;
  }

  .ObjectiveMaturityRow-description {
    background-color: $mirage;
    border: solid 1px $martinique2;
    resize: none;
    width: 100%;

    &::placeholder {
      color: $gunPowder;
    }
  }

  .ObjectiveMaturityRow-name,
  .ObjectiveMaturityRow-description {
    color: $cadetBlue;
  }

  .ObjectiveMaturityRow-description {
    overflow-y: hidden;

    &:focus {
      // height: 100px;
    }
  }

  .ObjectiveMaturity-CountBox {
    margin-left: auto;
  }
}
