@import "~styles/colors.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";
@import "~styles/fontSizes.scss";
@import "~styles/fontFamilies.scss";

/* Blink for Webkit and others
(Chrome, Safari, Firefox, IE, ...)
*/

$buttonWidth: 80px;
$buttonHeight: 20px;

.NodeForm {
  text-align: justify;
  display: flex;
  justify-content: center;

  .NodeForm-container {
    width: 460px;
  }
  
  .NodeForm-header {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 17px 16px 15px 19px;
    background-color: $steelGray;
    margin-top: 20px;
    height: 70px;

    span {
      color: #39a1c3;
    }
  }
  .NodeForm-input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 61px;
    margin: 2px 10px 0 0;
    padding: 17px 16px 15px 19px;
    background-color: #191924;
    border: solid 1px rgba(50, 50, 73, 0.42);
    margin-top: 10px;

    input {
      width: 100%;
      background-color: #191924;
      border: 1px solid transparent;
      color: $white;
      display: block;

      &:focus {
        outline-style: none;
      }
    }
  }
  .NodeForm-texarea {
    margin-top: 4px;
    align-items: center;
    textarea {
      border: solid 1px rgba(50, 50, 73, 0.42);
      padding: 21px 0 0 21px;
      width: 100%;
      height: 100px;
      color: $white;
      resize: none;
      background-color: #191924;
  
      &:focus {
        outline-style: none;
      }
    }
  }

  .NodeForm-button-group {
    margin-top: 5px;
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
    height: 60px;

    .NodeForm-updateButton {
      background: $olivetone;
      margin-right: 10px;
      border-radius: $buttonWidth / 2;
      border: none;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth;

      &:hover {
        background: $greenYellow3;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }

    .NodeForm-createButton {
      background: $bostonBlue;
      margin-right: 10px;
      border-radius: $buttonWidth / 2;
      border: none;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth;

      &:hover {
        background: $shakespeare;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }

    .NodeForm-cancelButton {
      background: $steelGray3;
      border-radius: $buttonWidth / 2;
      border: 1px solid $bostonBlue;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth;

      &:hover {
        background: $scooter;
        // border: 1px solid $turquoiseBlue;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }

    .NodeForm-archiveButton {
      background: $steelGray3;
      margin-right: 10px;
      border-radius: $buttonWidth / 2;
      border: 1px solid #d4a302;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth;

      &:hover {
        background: #d4a302;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }
  }
}

.blink-me {
  animation: blinker 5s ease-in-out;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
