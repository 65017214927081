@import '~styles/margins.scss';

.RecomendationForm {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  padding: 20px;
  width: 100%;
  height: 637px;
  .RecomendationForm-rightPanel {
    display: flex;
    max-height: 600px;
    max-width: 50%;
    width: 50%;
    // padding: 20px;
  }
  .RecomendationForm-leftPanel {
    display: flex;
    max-height: 600px;
    max-width: 50%;
    width: 50%;
    // padding: 20px;
  }
}
