@import "styles/colors";
@import "styles/fontFamilies";

body {
  @include quickSandMedium;
  padding: 0;
  margin: 0;
  color: $white;
  background-color: $ebony;
}
