@import "~styles/colors.scss";
@import "~styles/fontSizes.scss";
@import "~styles/fontFamilies.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";

.AnswerFormWelcomePage {
  width: 100%;
  height: 100%;

  h2 {
    @include quickSandMedium;
    font-size: $fontSizeGiant;
    color: $graySuit;
    text-align: center;
    margin: $margin2 auto;
  }

  p {
    @include quickSandMedium;
    font-size: $fontSizeNormal;
    color: $concret;
    text-align: center;
    margin: 10px 40px 40px 40px;
    padding: 20px 40px 20px 40px;
    border: solid 1px rgba(0, 205, 253, 0.4);
    background-color: $steelGray6;
  }
}
