@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/shadows.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

$buttonWidth: 130px;
$buttonHeight: 40px;

.LoginPage {
  form {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 10px;
    column-gap: 50px;
    margin-bottom: $margin2;

    @media (min-width: 768px) {
      grid-template-columns: auto 1fr;
    }
  }

  .LoginPage-button-group {
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    height: 60px;

    .LoginPage-createButton {
      background: $bostonBlue;
      border-radius: $buttonWidth / 2;
      border: none;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth * 2;

      &:hover {
        background: $shakespeare;
        cursor: pointer;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }
  }
}
