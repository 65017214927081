@import "../../../styles/colors.scss";

.Questions-header {
  width: 90%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  &::-webkit-scrollbar-track {
    height: 4px;
    background-color: transparent;
    border: 6px solid transparent;
    border-radius: 12px;
  }
  &::-webkit-scrollbar {
    height: 4px;
    width: 6px;
    border: 6px solid transparent;
    border-radius: 12px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    height: 4px;
    background: transparent;
    border: 6px solid transparent;
    border-radius: 12px;
  }
  .Questions-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    font-size: 20px;
    font-weight: 500;
    color: $trout2;
    cursor: pointer;
    .Questions-number-of-questions {
      width: 22px;
      height: 22px;
      margin-left: 5px;
      border-radius: 2px;
      background-color: $charade;
      color: $trout2;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .selected {
    padding-bottom: 9px;
    border-bottom: solid 3px;
    border-image: linear-gradient(to top, #2d73a0, #5ce7ea) 30;
    color: $iron;
    .Questions-number-of-questions {
      background-color: $olivetone;
      color: $iron;
    }
  }
}
