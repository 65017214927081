@import "~styles/colors.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";
@import "~styles/fontSizes.scss";

$buttonWidth: 240px;
$buttonHeight: 45px;

.SelectNode-container {
  height: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  .SelectNode-row {
    .SelectNode-form-group {
      select {
        width: 100%;
        border: none;
        padding: 10px;
        font-weight: 600;
        color: #f2f2f2;
        background-color: $steelGray4;

        &:focus {
          outline: none;
        }
      }
    }
    .SelectNode-title {
      margin-top: 25px;
      text-align: center;
    }
    .SelectNode-list {
      display: flex;
      flex-wrap: wrap;
      border: solid 1px #242432;
      margin-top: 15px;
      height: 480px;
      padding: 10px;
      overflow-y: scroll;
      &::-webkit-scrollbar-track {
        background-color: #22222e;
        border: 6px solid transparent;
        border-radius: 12px;
      }
      &::-webkit-scrollbar {
        width: 6px;
        border: 6px solid transparent;
        border-radius: 12px;
        background: #2a2a38;
      }
      &::-webkit-scrollbar-thumb {
        background: #2a2a38;
        border: 6px solid transparent;
        border-radius: 12px;
      }
      h1 {
        margin: 10px 10px 10px 0;
      }
      .SelectNode-tag {
        width: 220px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        margin: 3px;
        color: #f2f2f2;
        background: $easternBlue;
        font-weight: 800;
        small {
          color: $gunPowder;
          font-weight: 600;
          font-size: 0.8rem;
        }
        button {
          border: none;
          cursor: pointer;
          color: #f2f2f2;
          background: transparent;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
  .SelectNode-button-group {
    margin-top: 5px;
    display: flex;
    padding: 20px;
    justify-content: flex-end;

    .SelectNode-applyButton {
      background: $bostonBlue;
      border-radius: $buttonWidth / 2;
      border: none;
      color: $white;
      font-size: $fontSizeBig;
      height: $buttonHeight;
      width: $buttonWidth;

      &:hover {
        background: $shakespeare;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }

    .SelectNode-cancelButton {
      background: $steelGray3;
      margin-right: 20px;
      border-radius: $buttonWidth / 2;
      border: 1px solid $bostonBlue;
      color: $white;
      font-size: $fontSizeBig;
      height: $buttonHeight;
      width: $buttonWidth;

      &:hover {
        background: $vulcan;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }
  }
}
