@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';
@import '~styles/shadows.scss';

.TenantUserManagementPage {
  text-align: justify;
  display: flex;
  justify-content: center;

  .TenantTemplateManagementPage-body {
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .TenantTemplateManagementPage-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      .TenantTemplateManagementPage-leftPanel {
        display: flex;
        flex-direction: column;
        width: 50%;

        .TenantTemplateManagementPage-input-container {
          display: flex;
          margin: 10px;
          background-color: #191924;
          border: solid 1px rgba(50, 50, 73, 0.42);
          .input-icon {
            height: 50px;
            min-width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .TenantTemplateManagementPage-input {
            display: flex;
            align-items: center;
            width: 100%;
            height: 50px;
            padding: 17px 16px 15px 5px;
            background-color: #191924;
            border: none;
            outline: none;

            color: $white;
            &:focus {
              outline-style: none;
            }
          }
        }
        .TenantTemplateManagementPage-panels {
          width: 100%;
          display: flex;
          flex-direction: row;
          .TenantTemplateManagementPage-panel {
            width: 50%;
            display: flex;
            justify-content: center;
            // align-items: center;
            flex-direction: column;
            padding: 10px;

            .TenantUserManagementPage-panel-header {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding: 17px 16px 15px 22px;
              background-color: $steelGray;
              height: 70px;

              .title {
                width: max-content;
                color: #8ca2aa;
              }

              span {
                color: #39a1c3;
              }
              .master-icon {
                height: 40px;
                width: 40px;
                padding: 7px;
                border-radius: 7px;

                &:hover {
                  color: $scooter;
                  background-color: $gunPowder;
                }
                &:active {
                  color: $leanit101BlueLight3;
                  background-color: $gunPowderDisabled;
                }
              }
            }
          }
        }
      }
      .TenantTemplateManagementPage-rightPanel {
        display: flex;
        width: 50%;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        .TenantTemplateManagementPage-radar-actions {
          background-color: $steelGray2;
          width: 100%;
          height: 52px;
          margin: 10px;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .radar-icons {
            width: max-content;
            display: flex;
            flex-direction: row;
            .radar-icon {
              height: 40px;
              width: 40px;
              padding: 7px;
              border-radius: 7px;

              &:hover {
                color: $scooter;
                background-color: $gunPowder;

                .radar-icon-title {
                  display: inline;
                }
              }
              &:active {
                color: $leanit101BlueLight3;
                background-color: $gunPowderDisabled;
              }
            }
            .radar-icon-title-wrapper {
              position: relative;
              .radar-icon-title {
                display: none;
                position: absolute;
                background-color: $steelGray3;
                width: max-content;
                padding: 5px;
                left: -90%;
                @include quickSandSemiBold;
                font-size: $fontSizeSmall;
                color: $concret;
                background-color: $vulcan;
                border: solid 1px $martinique;
                box-shadow: $boxShadow1;
                z-index: 99999;
              }
              .radar-icon:hover + .radar-icon-title {
                display: block;
              }
            }
          }
          .submitted-tag {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
          }
        }
        .TenantTemplateManagementPage-radar-chart {
          width: 100%;
          padding: 10px;
          min-height: 500px;
          height: 500px;
        }
      }
    }
  }

  .TenantUserManagementPage-header {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    padding: 17px 16px 15px 19px;
    background-color: $steelGray;
    margin-top: 20px;
    height: 70px;
    .TenantUserManagementPage-header-content {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: flex-start;

      .title {
        color: #8ca2aa;
      }

      span {
        color: #39a1c3;
      }
    }
    .TenantUserManagementPage-header-help {
      .help-message-wrapper {
        position: relative;
        .help-message {
          display: none;
          position: absolute;
          background-color: $steelGray3;
          width: max-content;
          max-width: 300px;
          margin: 10px;
          padding: 10px;
          right: -20%;
          @include quickSandSemiBold;
          font-size: $fontSizeSmall;
          color: $concret;
          background-color: $vulcan;
          border: solid 1px $martinique;
          box-shadow: $boxShadow1;
          z-index: 99999;
        }
        .help-icon:hover + .help-message {
          display: block;
        }
        .help-icon {
          margin: 5px;
        }
      }
    }
  }
  .TenantUserManagementPage-footer {
    margin-top: 5px;
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
    height: 80px;

    .TenantUserManagementPage-add-user-button {
      background: $steelGray3;
      border-radius: 20px;
      border: 1px solid $bostonBlue;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: 40px;
      width: 40px;
      margin-right: 20px;

      &:hover {
        background: $scooter;
        cursor: pointer;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }
  }
}
