@import "~styles/margins.scss";

.ObjectivesForm {
  height: 637px;

  .ObjectivesForm-rightPanel {
    margin-top: 5px;
    margin-right: 5px;
    width: 450px;
  }
}
