@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

/* Blink for Webkit and others
(Chrome, Safari, Firefox, IE, ...)
*/

$buttonWidth: 80px;
$buttonHeight: 20px;

.Persona {
  text-align: justify;
  display: flex;
  justify-content: center;

  .Persona-container {
    width: 480px;
  }

  .Persona-list {
    height: 140px;

    .ManagePersona-list-content {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      height: 88px;
      overflow-y: scroll;
      width: 100%;

      &::-webkit-scrollbar-track {
        background-color: $vulcan;
        border: 6px solid $vulcan;
        border-radius: 12px;
      }
      &::-webkit-scrollbar {
        width: 6px;
        border: 6px solid $martinique;
        border-radius: 12px;
        background: $martinique;
      }
      &::-webkit-scrollbar-thumb {
        background: $martinique;
        border: 6px solid $martinique;
        border-radius: 12px;
      }
    }
  }
  .Persona-tag {
    display: flex;
    width: 170px;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 3px;
    color: #f2f2f2;
    background: $easternBlue;
    font-weight: 800;
    .persona-title {
      font-size: $fontSizeSmall;
    }
    small {
      color: $gunPowder;
      font-weight: 600;
      font-size: 0.8rem;
    }
  }

  .Persona-header {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 17px 16px 15px 19px;
    background-color: $steelGray;
    margin-top: 20px;
    height: 70px;

    span {
      color: #39a1c3;
    }
  }
  .Persona-input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 61px;
    margin: 2px 10px 0 0;
    padding: 17px 16px 15px 19px;
    background-color: #191924;
    border: solid 1px rgba(50, 50, 73, 0.42);
    margin-top: 10px;

    input {
      width: 100%;
      background-color: #191924;
      border: 1px solid transparent;
      color: $white;
      display: block;

      &:focus {
        outline-style: none;
      }
    }
  }
  .Persona-texarea {
    margin-top: 4px;
    align-items: center;
    textarea {
      border: solid 1px rgba(50, 50, 73, 0.42);
      padding: 21px 0 0 21px;
      width: 100%;
      height: 100px;
      color: $white;
      resize: none;
      background-color: #191924;

      &:focus {
        outline-style: none;
      }
    }
  }

  .Persona-button-group {
    margin-top: 5px;
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
    height: 60px;

    .Persona-updateButton {
      background: $olivetone;
      margin-right: 10px;
      border-radius: $buttonWidth / 2;
      border: none;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth;

      &:hover {
        background: $greenYellow3;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }

    .Persona-createButton {
      background: $bostonBlue;
      margin-right: 10px;
      border-radius: $buttonWidth / 2;
      border: none;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth;

      &:hover {
        background: $shakespeare;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }

    .Persona-cancelButton {
      background: $steelGray3;
      border-radius: $buttonWidth / 2;
      border: 1px solid $bostonBlue;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth;

      &:hover {
        background: $scooter;
        // border: 1px solid $turquoiseBlue;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }

    .Persona-archiveButton {
      background: $steelGray3;
      margin-right: 10px;
      border-radius: $buttonWidth / 2;
      border: 1px solid #d4a302;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth;

      &:hover {
        background: #d4a302;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }
  }
}

.blink-me {
  animation: blinker 5s ease-in-out;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
