@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/margins.scss';

$buttonWidth: 240px;
$buttonHeight: 45px;

.AreaContainer {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  width: 300px;

  .colorPicker {
    position: fixed;
  }
  .AreaContainer-content {
    height: 200px;
    overflow-y: auto;
    border: solid 1px #242432;

    &::-webkit-scrollbar-track {
      background-color: #22222e;
      border: 6px solid transparent;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border: 6px solid transparent;
      border-radius: 12px;
      background: #2a2a38;
    }
    &::-webkit-scrollbar-thumb {
      background: #2a2a38;
      border: 6px solid transparent;
      border-radius: 12px;
    }
  }
}
