@import '~styles/colors.scss';

.node {
  cursor: pointer;
  stroke: $gunPowderDisabled;
  stroke-width: 1px;
  font-size: 0.5rem;
}

// .node:hover {
//   stroke: $leanit101BlueLight;
//   stroke-width: 1.5px;
// }

.node--leaf {
  fill: $gunPowderDark;
}
.node-color {
  &:hover {
    stroke-width: 3px;
  }
}
.node-color-0 {
  &:hover {
    stroke: #1f77b4;
  }
}
.node-color-1 {
  &:hover {
    stroke: #ff7f0e;
  }
}
.node-color-2 {
  &:hover {
    stroke: #2ca02c;
  }
}
.node-color-3 {
  &:hover {
    stroke: #d62728;
  }
}
.node-color-4 {
  &:hover {
    stroke: #9467bd;
  }
}
.node-color-5 {
  &:hover {
    stroke: #8c564b;
  }
}
.node-color-6 {
  &:hover {
    stroke: #e377c2;
  }
}
.node-color-7 {
  &:hover {
    stroke: #7f7f7f;
  }
}
.node-color-8 {
  &:hover {
    stroke: #bcbd22;
  }
}
.node-color-9 {
  &:hover {
    stroke: #17becf;
  }
}

.label {
  font-family: Quicksand;
  font-weight: 500;
  font-size: 1.4rem;
  font-variant: numeric;
  text-anchor: middle;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.24);
}

// .label-selected {
//   font-weight: 900;
//   font-size: 1.2rem;
// }

// .label-child{
//   font-weight: 600;
//   font-size: 1rem;
// }

.label,
.node--root,
.node--leaf {
  // pointer-events: none;
}

.meter-background {
  fill: #dfeafd;
}

.meter-foreground {
  fill: #0499f0;
}

.blinking {
  border-radius: 20px;
  animation: blinkingText 3s ease-out;
}

@keyframes blinkingText {
  0% {
    background-color: $comet;
  }
  33% {
    background-color: $comet;
  }
  // 66%{    background-color: $comet; }
  100% {
    background-color: $steelGray4;
  }
}
