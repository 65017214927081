@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

$buttonMinWidth: 80px;
$buttonHeight: 20px;

.FormButton {
  background: $steelGray3;
  border-radius: $buttonHeight / 2;
  border: none;
  color: $white;
  @include quickSandSemiBold;
  font-size: $fontSizeSmall;
  height: $buttonHeight;
  min-width: $buttonMinWidth;
  margin-right: 10px;

  &:focus {
    outline-style: none;
  }

  &.FormButton--green {
    background: $olivetone;

    &:hover {
      background: $greenYellow3;
    }

    &:disabled {
      background: #48b9d824;
      color: #ffffff5e;
    }
  }
  &.FormButton--red {
    background: $steelGray3;
    border: 1px solid rgb(172, 0, 0);

    &:hover {
      background: red;
    }

    &:disabled {
      background: #48b9d824;
      color: #ffffff5e;
    }
  }
  &.FormButton--gray {
    background: $steelGray3;
    border: 1px solid $bostonBlue;

    &:hover {
      background: $scooter;
    }

    &:disabled {
      background: #48b9d824;
      color: #ffffff5e;
    }
  }
  &.FormButton--blue {
    background: $bostonBlue;
    border: 1px solid $bostonBlue;

    &:hover {
      background: $scooter;
    }

    &:disabled {
      background: #48b9d824;
      color: #ffffff5e;
    }
  }
}
