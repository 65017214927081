@import "~styles/colors.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";
@import "~styles/fontSizes.scss";
@import "~styles/shadows.scss";
@import "~styles/fontSizes.scss";
@import "~styles/fontFamilies.scss";

.ResetPasswordPage {
  form {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 10px;

    @media (min-width: 768px) {
      grid-template-columns: auto 1fr;
    }
  }
}
