@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';

$maturityLevelBoxSize: 33px;

.MaturityLevel {
  margin-right: $margin8;
  .MaturityLevel-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  .MaturityLevel-label {
    font-family: $quickSandFontFamily;
    font-weight: 500;
    font-size: $fontSizeNormal;
    margin-bottom: 1.563rem !important;
    text-align: justify;
    align-items: center;
  }

  .MaturityLevel-countBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $maturityLevelBoxSize;
    height: $maturityLevelBoxSize;
    background-color: $tuna;
    border-radius: 0.125rem;
    color: $turquoiseBlue;
  }

  .MaturityLevel-row {
    display: grid;
    grid-template-columns: $maturityLevelBoxSize auto auto;
    margin-bottom: 8px;

    &.MaturityLevel-row--disabled .MaturityLevel-countBox {
      color: $manatee;
      background-color: $steelGray4;
    }
  }

  .MaturityLevel-rowName {
    width: 98%;
    max-height: 2rem;
    border: solid 1px $martinique2;
    margin-right: 6px;

    &::placeholder {
      color: $gunPowder;
    }
  }

  .MaturityLevel-rowDescription {
    width: 98%;
    max-height: 2rem;
    border: solid 1px $martinique2;
    background-color: $mirage !important;
    resize: none;
    &::placeholder {
      color: $gunPowder;
    }
  }

  .MaturityLevel-rowName,
  .MaturityLevel-rowDescription {
    color: $cadetBlue;
    padding: 0 $padding3;
  }

  .MaturityLevel-row--disabled .MaturityLevel-rowName::placeholder,
  .MaturityLevel-row--disabled .MaturityLevel-rowDescription::placeholder {
    color: $gunPowderDisabled;
  }

  .MatutrityLevel-countValue {
    @include quickSandBold;
    font-size: $fontSizeNormal;
    color: $turquoiseBlue;
  }
}
