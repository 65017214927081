.AddQuestion {
  width: 100%;
  .AddQuestion--btn {
    width: 100%;
    height: 42px;
    margin: 24px 0px 0 5px;
    padding: 10px 31px 10px 11px;
    border: solid 1px #3d3d4f;
    background-color: #82a40c;
    font-size: 18px;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    &:hover {
      border: solid 1px #7c7c83;
      background-color: #698506;
    }
    span {
      color: white;
      width: 22px;
      height: 22px;
      margin-left: 10px;
    }
    &:disabled {
      cursor: pointer;
      color: #ffffff5e;
      span {
        color: #ffffff5e;
      }
    }
  }
}
