@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

$buttonWidth: 80px;
$buttonHeight: 20px;

.ManagePersona {
  text-align: justify;
  display: flex;
  justify-content: center;
  width: 860px;

  .ManagePersona-container {
    width: 860px;

    .ManagePersona-list {
      height: 300px;
      padding: 10px;
      display: flex;
      margin-left: 10px;
      margin-right: 10px;
      align-items: center;
      flex-direction: column;
      border: solid 1px rgba(50, 50, 73, 0.42);

      .ManagePersona-list-content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        height: 220px;
        overflow-y: scroll;
        width: 100%;

        &::-webkit-scrollbar-track {
          background-color: $vulcan;
          border: 6px solid $vulcan;
          border-radius: 12px;
        }
        &::-webkit-scrollbar {
          width: 6px;
          border: 6px solid $martinique;
          border-radius: 12px;
          background: $martinique;
        }
        &::-webkit-scrollbar-thumb {
          background: $martinique;
          border: 6px solid $martinique;
          border-radius: 12px;
        }
      }
    }
    .ManagePersona-tag {
      display: flex;
      width: 195px;
      height: 50px;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      margin: 3px;
      color: #f2f2f2;
      background: $martinique;
      border: solid 1px rgba(50, 50, 73, 0.42);

      font-weight: 800;
      .persona-title {
        font-size: $fontSizeSmall;
      }
      small {
        color: $gunPowder;
        font-weight: 600;
        font-size: 0.8rem;
      }
      .launch-icon {
        margin-left: 6px;
        color: $turquoiseBlue;
        cursor: pointer;
        font-size: 20px;
        &:hover {
          color: $turquoiseBlue0;
        }
      }
    }

    .ManagePersona-header {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: space-evenly;
      padding: 17px 16px 15px 19px;
      background-color: $steelGray;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      height: 70px;
      // width: 96%;

      span {
        color: #39a1c3;
      }
    }

    .ManagePersona-input {
      display: flex;
      flex-wrap: nowrap;
      margin: 2px 0;
      padding: $padding2;
      background-color: #191924;
      border: solid 1px rgba(50, 50, 73, 0.945);
      width: 100%;

      input {
        width: 100%;
        background-color: #191924;
        border: 1px solid transparent;
        color: $white;
        display: block;

        &:focus {
          outline-style: none;
        }
      }

      small {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        .foundTitle {
          color: $leanit101BlueLight;
        }
      }
    }

    .ManagePersona-button-group {
      margin-top: 0px;
      display: flex;
      padding: 10px;
      justify-content: flex-end;
      align-items: center;
      height: 60px;

      .ManagePersona-cancelButton {
        background: $steelGray3;
        border-radius: $buttonWidth / 2;
        border: 1px solid $bostonBlue;
        color: $white;
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        height: $buttonHeight;
        width: $buttonWidth;
        margin-right: 20px;

        &:hover {
          background: $scooter;
          // border: 1px solid $turquoiseBlue;
        }

        &:focus {
          outline-style: none;
        }

        &:disabled {
          background: #48b9d824;
          color: #ffffff5e;
        }
      }

      .ManagePersona-createButton {
        background: $bostonBlue;
        margin-right: 10px;
        border-radius: $buttonWidth / 2;
        border: none;
        color: $white;
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        height: $buttonHeight;
        width: $buttonWidth;

        &:hover {
          background: $shakespeare;
        }

        &:focus {
          outline-style: none;
        }

        &:disabled {
          background: #48b9d824;
          color: #ffffff5e;
        }
      }

      .ManagePersona-archiveButton {
        background: $steelGray3;
        margin-right: 10px;
        border-radius: $buttonWidth / 2;
        border: 1px solid #d4a302;
        color: $white;
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        height: $buttonHeight;
        width: $buttonWidth;

        &:hover {
          background: #d4a302;
        }

        &:focus {
          outline-style: none;
        }

        &:disabled {
          background: #48b9d824;
          color: #ffffff5e;
        }
      }
    }
  }
}

.blink-me {
  animation: blinker 5s ease-in-out;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
