@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

$buttonWidth: 130px;
$buttonHeight: 40px;

.SignUpPage {
  text-align: justify;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  height: 100%;
  @include quickSandSemiBold;

  .SignUpPage-container {
    width: 550px;
    background-color: $mirage;
  }

  .error {
    color: $errorMessage;
  }

  .SignUpPage-body {
    padding: 5 20 25 20;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .googleSignIn {
    height: 50px;
    // padding: 20px;
    justify-content: center;
  }

  .SignUpPage-field-container {
    margin-top: 20px;

    .SignUpPage-field-description {
      min-width: 400px;
      padding: 10px;
      background-color: $steelGray2;
    }
  }

  .SignUpPage-header {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 17px 16px 15px 19px;
    background-color: $steelGray;
    height: 70px;

    span {
      color: #39a1c3;
      font-size: $fontSizeTall;
      font-weight: 800;
    }
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    background-color: #191924;
    border: solid 1px rgba(50, 50, 73, 1);
    margin-top: 10px;
    input {
      margin-top: 0;
      border: solid 1px transparent;
      width: 338px;
    }
  }

  .visitility-icon {
    width: 60px;
    color: $gunPowder;
    cursor: pointer;
  }

  .SignUpPage-input {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 400px;
    height: 61px;
    color: white;
    padding: 17px 16px 15px 19px;
    border: solid 1px rgba(50, 50, 73, 1);
    background-color: #191924;
    margin-top: 10px;

    &.SignUpPage-validation-error {
      border: solid 1px $errorMessage;
      padding: 17px 16px 15px 19px;
    }

    input {
      background-color: #191924;
      color: $white;
      display: block;

      &:focus {
        outline-style: none;
      }
    }
  }

  .SignUpPage-button-group {
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    height: 60px;

    .SignUpPage-createButton {
      background: $bostonBlue;
      border-radius: $buttonWidth / 2;
      border: none;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth * 2;

      &:hover {
        background: $shakespeare;
        cursor: pointer;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }
  }
}
