@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';
@import '~styles/shadows.scss';

.TenantUsers-container {
  width: 100%;

  .TenantUsers-role {
    display: flex;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 14px;
    padding: 2px;
    font-size: 8px;
    color: $concret;
    border: solid 1px rgba(50, 50, 73, 1);
    border-radius: 2px;
    background-color: $steelGray6;
    &:disabled {
      background: #48b9d824;
      color: #ffffff5e;
    }
    &:hover {
      background-color: $martinique;
      border: solid 1px $manatee;
    }
  }

  .TenantUsers-role-user-selected {
    background-color: $christi;
    color: $concret;
    border: solid 1px $greenYellow;
  }

  .TenantUsers-role-admin-selected {
    background-color: $leanit101BlueLight4;
    color: $concret;
    border: solid 1px $leanit101BlueLight3;
  }

  .TenantUsers-role-super-selected {
    background-color: $templateTag;
    color: $concret;
    border: solid 1px $templateTag2;
  }

  .TenantUsers-profilePic {
    border-radius: 50%;
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    z-index: 0;

    cursor: progress;
  }

  .TenantUsers-content-title {
    color: $gunPowder;
    &:hover {
      color: $turquoiseBlue;
      cursor: pointer;
    }
  }

  .TenantUsers-content-tag {
    display: flex;
    flex-direction: column;
    transition: ease-in-out 0.3s;
  }

  .TenantUser-user-tag-top-panel {
    display: flex;
    width: 265px;
    justify-content: space-between;
  }

  .TenantUsers-user-name {
    font-size: 12px;
    font-weight: 600;
    color: $turquoiseBlue;
    width: 100%;
    margin-top: 10px;
  }

  .TenantUsers-user-email {
    font-size: 10px;
    font-weight: 500;
    color: $turquoiseBlue;
    width: 100%;
  }

  .TenantUsers-content {
    min-height: 500px;
    max-height: 500px;
    overflow-y: scroll;
    display: flex;
    flex-flow: wrap;
    align-content: flex-start;

    &::-webkit-scrollbar-track {
      background-color: $vulcan;
      border: 6px solid $vulcan;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border: 6px solid $martinique;
      border-radius: 12px;
      background: $martinique;
    }
    &::-webkit-scrollbar-thumb {
      background: $martinique;
      border: 6px solid $martinique;
      border-radius: 12px;
    }

    .TenantUsers-pic-panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 60px;
    }

    .TenantUsers-role-panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80px;
    }

    .TenantUsers-user {
      border-radius: 5px;
      display: flex;
      align-items: center;
      height: 60px;
      margin: 5px;
      width: 350px;
      padding: 4px 10px 4px 10px;
      background-color: $steelGray4;
      font-size: 12px;
      font-weight: 500;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        transform: scale(1.02);
        background-color: $leanit101BlueLight4;
      }
      h1 {
        color: $concret;
      }
      .disabled {
        color: $trout2;
      }
    }
  }
}
