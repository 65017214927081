@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

.DashBoardListAssessmentUsers-container {
  width: 300px;

  .DashBoardListAssessmentUsers-assessment-name {
    @include quickSandLight;
    font-size: $fontSizeBig;
    margin: 20px;
    margin-top: 0px;
    color: #62c9fb;
    text-shadow: 1px 1px 1px rgb(0, 0, 0);
  }

  .DashBoardListAssessmentUsers-header {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    width: 100%;
    padding: 17px 16px 15px 19px;
    background-color: #242432;
    font-size: 14px;
    font-weight: 500;

    .DashBoardListAssessmentUsers-textLeft {
      text-align: initial;

      .DashBoardListAssessmentUsers-title {
        padding-bottom: 5px;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .DashBoardListAssessmentUsers-profilePic {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    z-index: 0;
  }

  .DashBoardListAssessmentUsers-user-persona {
    margin-left: 5px;
    margin-bottom: 40px;
    font-size: 10px;
    font-weight: 500;
    color: $turquoiseBlue;
    font-variant: small-caps;
  }

  .userPersonaContainer {
    width: 165px;
    margin-right: 5px;
  }

  .userTagContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tag {
    @include quickSandSemiBold;
    font-size: $fontSizeSmall;
    font-weight: 600;
    flex-shrink: 0;
    width: 45px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 18px;
    position: relative;
    background: $leanit101Blue;
    margin-right: 13px;
    color: white;
  }
  .tag:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 9px solid $vulcan;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
  }
  .tag:before {
    content: '';
    position: absolute;
    right: -9px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 9px solid $leanit101Blue;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
  }

  .DashBoardListAssessmentUsers-content {
    height: 400px;
    overflow-y: scroll;
    border: solid 1px #242432;

    &::-webkit-scrollbar-track {
      background-color: #22222e;
      border: 6px solid transparent;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border: 6px solid transparent;
      border-radius: 12px;
      background: #2a2a38;
    }
    &::-webkit-scrollbar-thumb {
      background: #2a2a38;
      border: 6px solid transparent;
      border-radius: 12px;
    }

    .DashBoardListAssessmentUsers-user {
      display: flex;
      align-items: center;
      width: 100%;
      height: 61px;
      margin: 0px 10px 0 0;
      padding: 22px 16px 15px 19px;
      background-color: transparent;
      border: solid 1px #242432;
      font-size: 14px;
      font-weight: 500;
      transition: ease-in-out 0.3s;

      &:hover {
        background-color: $vulcan1;
        cursor: pointer;
      }
      h1 {
        margin-left: 5px;
        color: $concret;
      }
      .disabled {
        color: $trout2;
      }
    }

    .selected {
      display: flex;
      background: #237d97;
      color: #ffffff;
      border-left: 5px solid #72c1db !important;
      padding: 17px 16px 15px 19px;
      align-items: center;
      width: 100%;
      height: 61px;
      opacity: 1;
      border: solid 1px $gunPowder;
      transition: ease-in-out 0.3s;

      h1 {
        color: white;
      }
    }
  }
}
