@import '~styles/margins.scss';
@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';
@import '~styles/shadows.scss';

.ObjectivesList {
  margin: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  // height: 100%;

  .ObjectivesList-title {
    padding: 15px;
    font-size: 1.25rem;
  }

  .ObjectivesList-objectives {
    max-height: 500px;

    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    max-width: 100%;
    width: 100%;
    padding-right: 3px;
    padding: 10px;
    margin: 10px;

    &::-webkit-scrollbar-track {
      background-color: $vulcan;
      border: 6px solid $vulcan;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 8px;
      border: 6px solid $martinique;
      border-radius: 12px;
      background: $martinique;
    }
    &::-webkit-scrollbar-thumb {
      background: $martinique;
      border: 6px solid $cinder;
      border-radius: 12px;
    }

    .ObjectivesList-selected {
      background-color: $leanit101BlueLight4 !important;
      .ObjectivesList-recommendation {
        background-color: $steelGray2 !important;
        transition: all 0.3s ease-in-out;
      }
    }
    .ObjectivesList-objective {
      @include quickSandSemiBold;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      // min-height: 60px;
      margin: 5px;
      padding: 10px;
      background-color: $steelGray4;
      font-size: 14px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      outline: none;
      border: none;
      color: white;

      &:hover {
        background-color: $leanit101BlueLight4;
        .ObjectivesList-recommendation {
          // border: 1px solid $steelGray4 !important;
          background-color: $steelGray2 !important;
          transition: all 0.3s ease-in-out;
        }
      }
      h1 {
        color: $concret;
      }
      .disabled {
        color: $trout2;
      }

      .ObjectivesList-objective-title {
        width: fit-content;
        text-overflow: ellipsis;
        white-space: nowrap;
        z-index: 1;
        font-size: 1.1rem;
        margin: 10px;
      }
      .ObjectivesList-objective-recommendations {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        max-width: 95%;
        overflow: hidden;
        height: 40px;
        transition: all 0.3s ease-in-out;

        &:hover {
          max-height: none;
        }
        .ObjectivesList-recommendation {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease-in-out;
          // border: 1px solid $leanit101BlueLight4;
          background-color: $leanit101BlueLight4;

          margin: 5px;
          padding-left: 5px;
          padding-right: 5px;

          width: max-content;
          height: 2rem;

          &:hover {
            .ObjectivesList-recommendation-remove {
              display: inline;
            }
          }
          .ObjectivesList-recommendation-remove {
            display: none;
            height: 1.2rem;
          }
          .ObjectivesList-recommendation-title {
            margin: 5px;
            position: static;
            width: fit-content;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            z-index: 1;
          }
        }
      }
    }
  }
}
