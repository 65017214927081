@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

.chart-label {
  @include quickSandBold;
  font-size: $fontSizeSmall;
  color: $concret;
}

.objectiveName {
  color: #39a1c3;
}
.dashboardBarChartContainer {
  border: solid 1px rgba(36, 36, 50, 0.1);
  padding-bottom: 5px;
  padding-left: 5px;
}
