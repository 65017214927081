@import "~styles/colors.scss";
@import "~styles/fontSizes.scss";
@import "~styles/fontFamilies.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";

.AnswerFormChoice {
  display: flex;
  align-items: center;
  margin-top: 3px;
  background-color: $steelGray5;
  padding: 0 $padding3;
  width: 100%;

  &.AnswerFormChoice--selected {
    background-color: $trout2;
  }

  input[type="checkbox"] {
    margin-right: $margin1;
    appearance: auto;
  }

  label {
    margin: $margin1 0;
    cursor: pointer;
    flex-grow: 1;
  }
}
