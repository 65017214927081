@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

/* Blink for Webkit and others
(Chrome, Safari, Firefox, IE, ...)
*/

$buttonWidth: 80px;
$buttonHeight: 20px;

.TriggerAssessment {
  text-align: justify;
  display: flex;
  justify-content: center;

  .TriggerAssessment-container {
    width: 100%;
  }

  .TriggerAssessment-header {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 17px 16px 15px 19px;
    background-color: $steelGray;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    height: 70px;

    span {
      color: #39a1c3;
    }
    .template-title {
      color: #8ca2aa;
    }
  }

  .TriggerAssessment-body {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
  }

  .TriggerAssessment-filter {
    justify-content: center;
  }

  .section-title {
    display: flex;
    padding: 10px;
    height: 43px;
    color: $graySuit;
  }

  .launch-icon {
    color: $graySuit;
    &:hover {
      color: white;
      cursor: pointer;
    }
    &:disabled {
      color: $gunPowder;
      &:hover {
        color: $gunPowder;
        cursor: not-allowed;
      }
    }
  }

  .TriggerAssessment-filter-results {
    width: 80%;
    margin-left: 20px;
    margin-right: 10px;
  }

  .TriggerAssessment-filter-results-content {
    height: 380px;
    overflow-y: scroll;
    width: 100%;

    &::-webkit-scrollbar-track {
      background-color: $vulcan;
      border: 6px solid $vulcan;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border: 6px solid $martinique;
      border-radius: 12px;
      background: $martinique;
    }
    &::-webkit-scrollbar-thumb {
      background: $martinique;
      border: 6px solid $martinique;
      border-radius: 12px;
    }
  }

  .TriggerAssessment-selected-assessments {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }

  .TriggerAssessment-selected-assessments-content {
    height: 380px;
    overflow-y: scroll;
    width: 100%;

    &::-webkit-scrollbar-track {
      background-color: $vulcan;
      border: 6px solid $vulcan;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border: 6px solid $martinique;
      border-radius: 12px;
      background: $martinique;
    }
    &::-webkit-scrollbar-thumb {
      background: $martinique;
      border: 6px solid $martinique;
      border-radius: 12px;
    }
  }

  .date-select {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 48%;
    height: 40px;
    padding: 10px;
    color: $concret;
    border: solid 1px rgba(50, 50, 73, 1);
    border-radius: 2px;
    background-color: $steelGray6;
    cursor: pointer;
    &:disabled {
      background: #48b9d824;
      color: #ffffff5e;
    }
    &:hover {
      background-color: $christi0;
      border: solid 1px $greenYellow;
    }
  }

  .date-selected {
    background-color: $christi;
    color: $concret;
    border: solid 1px $greenYellow;
  }

  .TriggerAssessment-not-found {
    @include quickSandSemiBold;
    font-size: $fontSizeNormal;
    color: $manatee;
    padding: 10px;
    font-weight: 400;
  }

  .TriggerAssessment-assessment-card {
    @include quickSandSemiBold;
    background-color: #237d97;
    font-size: $fontSizeSmall;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 1px;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #20748b;
    }

    .TriggerAssessment-assessment-card-content {
      display: flex;
      flex-direction: column;
      .node-name {
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        margin-top: 0.5em;
        color: #d2ecf6;
        padding: 1px 6px 1px 6px;
        border-radius: 3px;
        background-color: #043a46;
      }
      .node-type {
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        margin-top: 0.5em;
        color: #002744;
        margin-left: 10px;
      }
      .assessment-name {
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        padding-right: 10px;
        padding-bottom: 5px;
        color: $manatee;
        color: white;
      }
    }
  }

  .TriggerAssessment-assessment-selected-card {
    @include quickSandSemiBold;
    background-color: transparent;
    font-size: $fontSizeSmall;
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
    margin: 1px;
    margin-right: 10px;
    margin-left: 10px;
    border: solid 1px rgba(10, 205, 253, 0.5);
    border-radius: 2px;

    &:hover {
      background-color: $vulcan;
    }

    .TriggerAssessment-assessment-selected-card-content {
      display: flex;
      flex-direction: column;
      .selected-node-name {
        @include quickSandBold;
        font-size: $fontSizeSmall;
        margin-top: 0.5em;
        color: white;
        padding: 1px 6px 1px 6px;
        border-radius: 10px;
        background-color: $comet;
      }
      .selected-node-type {
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        margin-top: 0.5em;
        color: #8cc2ec;
        margin-left: 10px;
      }
      .selected-assessment-name {
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        padding-right: 10px;
        padding-bottom: 5px;
        color: $manatee;
        color: white;
      }
    }
  }

  .TriggerAssessment-button-group {
    margin-top: 5px;
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
    height: 60px;

    .TriggerAssessment-triggerButton {
      background: $bostonBlue;
      margin-right: 10px;
      border-radius: $buttonWidth / 2;
      border: none;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth * 1.9;

      &:hover {
        background: $shakespeare;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }

    .TriggerAssessment-cancelButton {
      background: $steelGray3;
      border-radius: $buttonWidth / 2;
      border: 1px solid $bostonBlue;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth;
      margin-right: 20px;

      &:hover {
        background: $scooter;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }

    .TriggerAssessment-clearButton {
      background: $steelGray3;
      border-radius: $buttonWidth / 2;
      border: 1px solid $clearButton;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth * 1.5;
      margin-right: 10px;

      &:hover {
        background: $clearButton;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }
  }
}
