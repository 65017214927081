@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

.data-row {
  display: flex;
  margin-bottom: 3px;
  border: 1px solid $scooter;
  background-color: $steelGray6;
}
.area-data-row {
  border-radius: 10%;
  margin-top: 10px;
  display: flex;
  margin-bottom: 3px;
  background-color: $steelGray2;
}
.total-row {
  border: 1px solid $gunPowder;

  margin-bottom: 10px;
  display: flex;
  width: max-content;
  min-width: 100%;
  background-color: $steelGray2;
}

.data-row-obj {
  display: flex;
  margin-bottom: 3px;
  margin-top: 3px;
  border: 1px solid $gunPowder;
  width: max-content;
  min-width: 100%;
  background-color: $steelGray6;
  &:hover {
    background-color: $vulcan;
    cursor: pointer;
  }
}

.data-col {
  border-radius: 2px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-left: 3px;
  margin: 2px;
  width: 35px;
  text-align: center;
  @include quickSandSemiBold;
  font-size: $fontSizeSmall;
}

.area-data-col {
  border-radius: 2px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-left: 3px;
  margin: 2px;
  width: 35px;
  text-align: center;
  display: flex;
  align-items: center;
  @include quickSandSemiBold;
  font-size: $fontSizeSmall;
}

.title-col {
  display: flex;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 5px;
  margin-right: 3px;
  // margin: 2px;
  text-align: end;
  justify-content: flex-end;
  align-items: flex-end;
  @include quickSandSemiBold;
  font-size: $fontSizeSmall;
  min-width: 180px;
  flex-shrink: 0;
}

.area-title-col {
  color: white;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1), 1px 1px 0px rgba(0, 0, 0, 0.35);
  padding: 5px;
  text-align: center;
  @include quickSandBold;
  font-size: $fontSizeTall;
  border-radius: 1px;
  min-width: 195px;
  flex-shrink: 0;
  margin-top: 3px;
}

.total-title-col {
  color: white;

  padding: 5px;
  margin-right: 3px;
  text-align: end;
  @include quickSandBold;
  font-size: $fontSizeNormal;
  border-radius: 3px;

  min-width: 180px;
  flex-shrink: 0;
}

.data-table-label {
  @include quickSandBold;
  font-size: $fontSizeSmall;
  color: $concret;
}

.areaContainerHeight {
  height: 320px;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  scroll-behavior: smooth;
  margin-bottom: 20px;
  overflow: auto;
  border: solid 1px #242432;

  &::-webkit-scrollbar-track {
    background-color: #22222e;
    border: 6px solid transparent;
    border-radius: 12px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    border: 6px solid transparent;
    border-radius: 12px;
    background: #2a2a38;
  }
  &::-webkit-scrollbar-thumb {
    background: #2a2a38;
    border: 6px solid transparent;
    border-radius: 12px;
  }
}
