@import "~styles/colors.scss";
@import "~styles/fontSizes.scss";
@import "~styles/fontFamilies.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";

.ApplyAssessmentForm {
  padding: 0px;
  height: 700px;
  .ApplyAssessmentForm-flex {
    height: 100%;
    .ApplyAssessmentForm-align {
      justify-content: center;
    }

    .ApplyAssessmentForm-SelectNode {
      height: 100%;
      background: $mirage;
      border-left: solid 1px rgba(50, 68, 73, 0.42);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
