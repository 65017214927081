@import '~styles/margins.scss';
@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';
@import '~styles/shadows.scss';
@import '~styles/paddings.scss';

.AnswerRecommendations {
  margin: 20px;
  display: flex;
  flex-direction: column;

  .AnswerRecommendations-header {
    @include quickSandSemiBold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $leanit101BlueLight3;
    font-weight: 700;
    .AnswerRecommendations-title {
      padding: 20px;
      font-size: 1.5rem;
      font-weight: 500;
      width: 100%;
      text-align: start;
      background-color: $steelGray3;
    }
    .AnswerRecommendations-description {
      color: $graySuit;
      padding: 20px;
      font-size: 1.2rem;
      font-weight: 400;
    }
  }

  .AnswerRecommendations-recommendations {
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 5px 15px 15px 15px;
    &::-webkit-scrollbar-track {
      background-color: $vulcan;
      border: 6px solid $vulcan;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 8px;
      border: 6px solid $martinique;
      border-radius: 12px;
      background: $martinique;
    }
    &::-webkit-scrollbar-thumb {
      background: $martinique;
      border: 6px solid $cinder;
      border-radius: 12px;
    }

    .AnswerRecommendations-recommendation {
      @include quickSandSemiBold;
      display: flex;
      flex-direction: row;
      align-items: space-between;
      justify-content: space-between;
      margin: 5px;
      padding: 15px;
      background-color: $steelGray4;
      font-size: 14px;
      font-weight: 500;
      transition: all 0.3s ease-in-out;
      outline: none;
      border: none;
      color: white;
      border: 1px solid $steelGray;
      &:hover {
        background-color: $tuna;
      }
      h1 {
        color: $concret;
      }
      .disabled {
        color: $trout2;
      }
      .AnswerRecommendations-recommendation-content-icon {
        width: 40px;
        height: 40px;
        margin-right: 15px;
        display: flex;
        align-self: center;
        justify-content: center;
      }
      .AnswerRecommendations-recommendation-knowmore {
        background-color: $leanit101BlueLight4;
        padding: 20px;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        min-width: max-content;
        min-height: 50px;
        max-height: 50px;

        cursor: pointer;
        &:hover {
          background-color: $leanit101BlueLight3;
        }
        .AnswerRecommendations-recommendation-link {
          text-decoration: none;
          outline: none;
          color: white;
          a {
            outline: none;
            text-decoration: none;
            padding: 2px 1px 0;
            margin: 5px;
          }
          .AnswerRecommendations-recommendation-icon {
            color: white;
            height: 1rem;
          }
        }
      }

      .AnswerRecommendations-recommendation-content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .AnswerRecommendations-recommendation-name {
          position: static;
          width: fit-content;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          z-index: 1;
          font-size: 1.1rem;
          padding: 5px;
          font-weight: 600;
        }
      }
      .AnswerRecommendations-recommendation-description {
        color: $graySuit;
        font-weight: 300;

        width: fit-content;
        text-overflow: ellipsis;
        overflow: scroll;
        padding: 5px;
        margin-right: 10px;
        // max-height: 40px;
        z-index: 1;
      }

      .AnswerRecommendations-recommendation-url {
        white-space: nowrap;
        position: static;
        width: 400px;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 5px;
        z-index: 1;
        color: $leanit101Blue;
      }
    }
  }
}
