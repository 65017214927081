@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/shadows.scss';

.Tree {
  background-color: $ebony;
  margin-right: 1em;

  .tag {
    @include quickSandSemiBold;
    font-size: $fontSizeSmall;
    font-weight: 600;
    flex-shrink: 0;
    width: 45px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 18px;
    position: relative;
    background: $leanit101Blue;
    margin-right: 13px;
    color: white;
  }
  .tag:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 9px solid $vulcan;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
  }
  .tag:before {
    content: '';
    position: absolute;
    right: -9px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 9px solid $leanit101Blue;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
  }

  .template-tag {
    @include quickSandSemiBold;
    font-size: $fontSizeSmall;
    font-weight: 600;
    flex-shrink: 0;
    width: 73px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 18px;
    position: relative;
    background: $templateTag;
    margin-right: 15px;
    color: white;
  }
  .template-tag:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 9px solid $vulcan;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
  }
  .template-tag:before {
    content: '';
    position: absolute;
    right: -9px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 9px solid $templateTag;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
  }
  .undeployed {
    @include quickSandSemiBold;
    font-size: $fontSizeSmall;
    font-weight: 600;
    flex-shrink: 0;
    width: 73px;
    display: flex;
    padding-right: 5px;
    align-items: center;
    justify-content: flex-end;
    height: 18px;
    position: relative;
    background: $templateTag3;
    margin-right: 15px;
    color: white;
  }
  .undeployed:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 9px solid $vulcan;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
  }
  .undeployed:before {
    content: '';
    position: absolute;
    right: -9px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 9px solid $templateTag3;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
  }

  .Tree-node {
    background-color: $steelGray4;
    color: $trout;
    border: solid 1px $martinique2;
    border-radius: 5px;
    min-height: 1em;
    width: 100%;
    &:hover {
      background: $mirage;
      border: solid 1px $tuna;
    }
    .Tree-node-content {
      max-width: 99%;
    }
    .Tree-node-link {
      a:link {
        color: $graySuit;
      }

      a:visited {
        color: $graySuit;
      }

      a:hover {
        color: $scooter;
      }

      a:active {
        color: $scooter;
      }
    }

    .launch-icon-selected {
      margin-left: 6px;
      color: $graySuit;
      cursor: pointer;
      font-size: 20px;
      &:hover {
        color: $scooter;
      }
    }

    .edit-icon {
      margin-left: 6px;
      color: $graySuit;
      cursor: pointer;
      font-size: 20px;
      &:hover {
        color: $scooter;
      }
    }

    .launch-icon {
      margin-left: 6px;
      color: $scooter;
      cursor: pointer;
      font-size: 20px;
      &:hover {
        color: $graySuit;
      }
    }

    .Tree-node-icon {
      color: $graySuit;
      margin-right: 5px;
      margin-top: 8px;
      cursor: pointer;

      &:hover {
        color: $scooter;
      }
    }

    .Tree-assessment-container {
      margin: 10px;
    }
    .Tree-users-container {
      margin: 10px;
    }

    .Tree-assessment-card {
      .Tree-assessment-card-mat-index {
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        font-weight: 500;
        flex-shrink: 0;
        width: 50px;
        background-color: $leanit101Blue;
      }

      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      width: 99%;

      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      padding-right: 2px;
      flex-shrink: 0;
      margin: 2px;
      color: $concret;
      background: $vulcan2;
      font-weight: 400;
      &:hover {
        background-color: $steelGray4;
      }
      .assessment-icons {
        min-width: fit-content;
        padding: 5px;
      }
      .assessment-name {
        margin-right: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .Tree-node-menu {
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      color: $concret;
      background-color: $vulcan;
      border: solid 1px $martinique;
      padding: 3px;
      box-shadow: $boxShadow1;
      margin-top: 10px;
      margin-right: 10px;

      span {
        overflow: auto;
        color: $concret;
        margin-left: 10px;
      }

      svg {
        font-size: $fontSizeNormal;
        color: $concret;
      }

      button {
        margin-top: 5px;
        margin-bottom: 5px;
        color: $concret;
        font-weight: 500;
      }

      button:hover {
        span {
          color: $ebonyClay;
          font-weight: 500;
        }

        svg {
          color: $ebonyClay;
        }

        background-color: $lightCyan;
        color: $ebonyClay;
      }

      hr {
        background-color: $martinique2;
        padding: 0px;
        margin: 5px;
      }
    }
  }

  .Tree-node-name {
    @include quickSandMedium;
    font-size: $fontSizeTall;
    color: $iron;
    margin-top: 2em;
    margin-left: 10px;
  }

  .Tree-node-type {
    @include quickSandSemiBold;
    font-size: $fontSizeSmall;
    margin-left: 10px;
    margin-top: 0.5em;
    color: $manatee;
  }

  .Tree-node-description {
    @include quickSandRegular;
    font-size: $fontSizeNormal;
    color: $dolphin;
    margin-left: 15px;
    margin-top: 0.8em;
  }

  a:link {
    color: white;
  }

  .dropdown-toggle:after {
    display: none;
  }

  a:hover {
    color: $shakespeare;
  }

  p,
  ul,
  ul ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul ul {
    margin-left: 0em;
  }

  ul li {
    border-left: none;
    list-style-type: disc;
    list-style-position: inside;
  }

  ul li:last-child {
    border-bottom: none;
  }

  ul li:before {
    border-bottom: none;
  }

  ul li:last-child:before {
    border-left: none;
  }
}
