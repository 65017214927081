@import '~styles/colors.scss';

.NavigationBar {
  .nav-link-border {
    border-radius: 30px;
  }

  .active {
    border: 1px solid $leanit101BlueLight3;
    border-radius: 30px;
  }
  background-color: $cinder;

  .flagCountry {
    width: 20px;
  }

  .profilePic {
    border-radius: 50%;
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    z-index: 0;
    &:hover {
      width: 45px;
      height: 45px;
      transition: ease-in-out 0.3s;
    }
    &:not(:hover) {
      width: 40px;
      height: 40px;
      transition: ease-in-out 0.3s;
    }
  }
}
