$astral: rgba(8, 11, 12, 1);
$astral1: rgb(18, 19, 29);
$purpleHeartBit: rgb(170, 98, 251);
$purpleHeart: rgba(130, 49, 221, 1);
$royalPurple: rgba(106, 51, 167, 1);
$scooter: rgba(57, 177, 215, 1);
$turquoiseBlue0: rgb(147, 246, 248);
$turquoiseBlue: rgba(92, 230, 233, 1);
$turquoiseBlue1: rgba(92, 230, 233, 0.5);
$pelorous: rgba(70, 181, 196, 1);
$shakespeare: rgba(72, 185, 216, 1);
$bostonBlue: rgba(58, 157, 184, 1);
$cadetBlue: rgba(178, 178, 199, 1);
$easternBlue: rgba(23, 134, 164, 1);
$lightCyan: #c9f7f7;
$leanit101Blue: #06485d;
$leanit101BlueLight: #19a2cf;
$leanit101BlueLight2: rgb(18, 142, 184);
$leanit101BlueLight4: #0e7597;
$leanit101BlueLight3: #0ba6da;
$leanit101Orange: #ff8010;
$leanit101OrangeDark: #cc6d19;
$leanit101Teal: #00b8ae;

$charade: #282839;
$charade2: rgba(36, 36, 50, 1);
$cinder: #1a1a27; // navbar
$concret: #f2f2f2;
$comet: #606078;
$dolphin: rgba(103, 103, 122, 1);
$ebony: #13131f; ///backgound APP
$ebonyClay: rgba(44, 44, 64, 1);
$graySuit: rgba(179, 179, 200, 1);
$gunPowder: rgba(68, 68, 86, 1);
$gunPowderDark: rgba(27, 27, 44, 0.5);
$gunPowderDisabled: rgba(68, 68, 86, 0.4);
$iron: #e7e7e8;
$manatee: #88889c;
$martinique: #313148;
$martinique2: rgba(50, 68, 73, 0.42);
$mirage: #161624; //background modal
$steelGray: #222230;
$steelGray2: rgba(33, 33, 47, 1);
$steelGray3: #191924;
$steelGray4: #1a1a27; // steps header modal
$steelGray5: #1e1e2c;
$steelGray6: rgba(31, 31, 49, 1);
$trout: rgba(74, 74, 91, 1);
$trout2: rgba(76, 76, 89, 1);
$tuna: rgba(55, 55, 73, 1);
$vulcan: #121220;
$vulcan1: rgb(12, 12, 20);
$vulcan2: rgba(18, 18, 32, 0.5);

$christi0: rgb(145, 182, 13);
$christi: rgba(130, 164, 12, 1);
$christi2: rgba(113, 146, 11, 1);
$LightgreenYellow: rgb(141, 160, 86);
$greenYellow: rgba(198, 251, 34, 1);
$greenYellow2: rgba(166, 197, 15, 1);
$greenYellow3: rgb(131, 156, 5);
$olivetone: rgba(95, 119, 12, 1);
$templateTag: rgb(155, 9, 155);
$templateTag2: rgb(245, 40, 245);
$templateTag3: rgb(82, 26, 82);

$errorBackground: rgb(172, 2, 2);
$errorMessage: rgb(165, 0, 0);
$danger: rgb(112, 0, 0);
$clearButton: rgb(172, 141, 2);

$white: #ffff;
$black: black;

// rainbow ["#6e40aa","#c83dac","#ff5375","#ff8c38","#c9d33a","#79f659","#28ea8d","#1eb8d0","#4775de","#6e40aa"]
