@import "~styles/colors.scss";
@import "~styles/fontSizes.scss";
@import "~styles/fontFamilies.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";

.AnswerFormPage {
  width: 100%;
  background-color: $steelGray3;

  .AnswerFormPage-objective {
    margin: 10px 40px 20px 40px;
    padding: 40px 40px 20px 40px;
    border: solid 1px rgba(0, 205, 253, 0.4);
    background-color: $steelGray6;
    h2 {
      padding: 0 $padding3;
      @include quickSandSemiBold;
      font-size: $fontSizeBig;
      color: $concret;
    }
    h3 {
      margin: $margin1 0;
      padding: 0 $padding3;
      @include quickSandLight;
      font-size: $fontSizeNormal;
      color: $cadetBlue;
    }
  }

  .AnswerFormPage-header {
    background-color: $steelGray3;
    h2 {
      padding: 10px 40px 10px 40px;
      @include quickSandMedium;
      font-size: $fontSizeGiant;
      color: $concret;
    }

    p {
      padding: 0px 40px 10px 40px;
      @include quickSandLight;
      font-size: $fontSizeNormal;
      color: $cadetBlue;
    }
  }

  .AnswerFormPage-questions {
    margin: $margin1 0;
  }
}
