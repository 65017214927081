@import "~styles/shadows.scss";
@import "~styles/margins.scss";
@import "~styles/colors.scss";

.Modal {
  width: 95%;
  top: 50%;
  left: 50%;
  border: 1px solid $mirage;
  transform: translate(-50%, -50%);
  margin-top: 30px;
  position: fixed;
  z-index: 9999;
  background-color: $mirage;
}
