@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

/* Blink for Webkit and others
(Chrome, Safari, Firefox, IE, ...)
*/

:root {
  --text: clamp(2px, 3px, 16px);
}
$buttonWidth: 80px;
$buttonHeight: 20px;

.MaturityMatrix {
  .MaturityMatrix-container {
    display: flex;
    width: 100%;
    height: 800px;
    flex-direction: column;
    .MaturityMatrix-header {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: space-evenly;
      padding: 17px 16px 15px 19px;
      background-color: $steelGray;
      margin-top: 20px;
      margin-left: 15px;
      margin-right: 19px;
      margin-bottom: 10px;
      min-height: 70px;

      span {
        color: #39a1c3;
      }
    }
    .MaturityMatrix-body {
      display: flex;
      flex-direction: column;
      //background-color: $steelGray4;
      height: 100%;
      max-height: 660;
      margin: 5px;
      padding: 5px;
      .MaturityMatrix-areas {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        padding: 5px;
        .MaturityMatrix-area {
          display: flex;
          flex-direction: row;
          //margin: 5px;
          height: 100%;

          .MaturityMatrix-area-name {
            display: flex;
            width: 220px;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            background-color: $leanit101BlueLight3;
            overflow: hidden;
            text-overflow: ellipsis;
            color: white;
            text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1),
              1px 1px 0px rgba(0, 0, 0, 0.35);
            font-size: 1.1rem;
            font-weight: 700;
          }
          .MaturityMatrix-area-objectives {
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: $steelGray3;
            row-gap: 2px;

            justify-content: space-between;
            .MaturityMatrix-objective {
              display: flex;
              flex-direction: row;
              background-color: $steelGray3;
              width: 100%;
              margin-left: 3px;
              min-height: 30px;
              .MaturityMatrix-objective-name {
                flex-shrink: 0;
                display: flex;
                color: white;
                min-width: 250px;
                max-width: 250px;
                min-height: 30px;
                align-items: center;
                justify-content: center;
                background-color: $charade2;
                height: 100%;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .MaturityMatrix-objective-maturity-descriptions {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 100%;
                width: 100%;
                margin-left: 2px;
              }
              .MaturityMatrix-objective-maturity-descriptions > div {
                text-align: center;
                display: flex;
                background-color: $charade2;
                border: $leanit101BlueLight3 1px solid;
                justify-content: center;
                align-items: center;
                padding: 10px;
                color: white;
                width: 100%;
                height: 100%;
                margin: 1px;
                overflow-wrap: break-word;
                //word-break: break-all;
              }

              .MaturityMatrix-objective-maturity-description-1 {
                font-size: 100%;
              }
              .MaturityMatrix-objective-maturity-description-2 {
                font-size: 95%;
              }
              .MaturityMatrix-objective-maturity-description-3 {
                font-size: 90%;
              }
              .MaturityMatrix-objective-maturity-description-4 {
                font-size: 85%;
              }
              .MaturityMatrix-objective-maturity-description-5 {
                font-size: 80%;
              }
              .MaturityMatrix-objective-maturity-description-6 {
                font-size: 80%;
              }
              .MaturityMatrix-objective-maturity-description-7 {
                font-size: 80%;
              }
              .MaturityMatrix-objective-maturity-description-8 {
                font-size: 75%;
              }
              .MaturityMatrix-objective-maturity-description-9 {
                font-size: 70%;
              }
              .MaturityMatrix-objective-maturity-description-10 {
                font-size: 65%;
              }
            }
          }
        }
      }
      .MaturityMatrix-matrix-header {
        display: flex;
        margin-left: 5px;
        margin-right: 2px;
        .MaturityMatrix-matrix-header-areas {
          display: flex;
          width: 220px;
          flex-shrink: 0;
          flex-grow: 0;
          align-items: center;
          background-color: $ebonyClay;
        }
        .MaturityMatrix-matrix-header-objectives {
          display: flex;
          width: 250px;
          flex-shrink: 0;
          margin-left: 3px;
          background-color: $ebonyClay;
        }

        .MaturityMatrix-matrix-header-maturity-levels {
          display: flex;
          flex-direction: column;
          width: 100%;
          background-color: $steelGray3;
          justify-content: center;
          align-items: center;
          margin-left: 2px;
          height: 100%;

          .MaturityMatrix-maturity-levels {
            display: flex;
            width: 100%;
            background-color: $steelGray3;

            .MaturityMatrix-maturity-level {
              display: flex;
              text-align: center;
              justify-content: space-evenly;
              align-items: center;
              color: white;
              width: 100%;
              min-height: 30px;
              margin-left: 1px;
              margin-right: 1px;
              padding: 10px;
              background-color: $ebonyClay;
            }
          }
          .MaturityMatrix-maturity-levels-text {
            display: flex;
            width: 100%;
            max-width: 988px;
            height: 30px;
            justify-content: center;
            align-items: center;
            margin-bottom: 2px;
            min-height: 40px;
            background-color: $ebonyClay;
          }
        }
      }

      .MaturityMatrix-matrix-header > * {
        font-size: 100%;
        text-align: center;
        display: flex;
        background-color: $steelGray;
        justify-content: center;
        align-items: center;
        color: white;
        overflow-wrap: break-word;
      }

      overflow-y: scroll;

      &::-webkit-scrollbar-track {
        background-color: $steelGray5;
        border: 6px solid $steelGray5;
        border-radius: 12px;
      }
      &::-webkit-scrollbar {
        width: 6px;
        border: 6px solid $martinique;
        border-radius: 12px;
        background: $martinique;
      }
      &::-webkit-scrollbar-thumb {
        background: $martinique;
        border: 6px solid $martinique;
        border-radius: 12px;
      }
    }

    .MaturityMatrix-footer {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .MaturityMatrix-cancelButton {
        background: $steelGray3;
        border-radius: $buttonWidth / 2;
        margin: 20px;
        border: 1px solid $bostonBlue;
        color: $white;
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        height: $buttonHeight;
        width: $buttonWidth;

        &:hover {
          background: $scooter;
        }

        &:focus {
          outline-style: none;
        }

        &:disabled {
          background: #48b9d824;
          color: #ffffff5e;
        }
      }
    }
  }
}
