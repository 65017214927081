@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

.react-autosuggest__container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  margin: 0px 10px 0 0;
  padding-left: 10px;
  background-color: #191924;
  border: solid 1px rgba(50, 50, 73, 0.42);
  position: relative;
}

.profile-pic {
  border-radius: 50%;
  width: 30px;
}

.spanEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-autosuggest__input {
  width: 100%;
  background-color: #191924;
  border: 1px solid transparent;
  color: $white;
  display: block;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 51px;
  width: 97%;
  border-radius: 2px;
  border: solid 1px rgba(50, 50, 73, 1);
  background-color: #191924;
  @include quickSandSemiBold;
  font-size: $fontSizeSmall;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;

  max-height: 400px;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    background-color: $vulcan;
    border: 6px solid $vulcan;
    border-radius: 12px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    border: 6px solid $martinique;
    border-radius: 12px;
    background: $martinique;
  }
  &::-webkit-scrollbar-thumb {
    background: $martinique;
    border: 6px solid $martinique;
    border-radius: 12px;
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  &:hover {
    background-color: #0b7497;
  }
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: solid 1px rgba(50, 50, 73, 0.42);
}

.react-autosuggest__suggestion--focused {
  background-color: #0c7eaf;
  color: rgb(51, 119, 107);
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

.name {
  margin-left: 10px;
  // line-height: 45px;
}

.highlight {
  color: #04f7ff;
  font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
  color: #ffee00;
}
