@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '../../../styles/fontFamilies.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';

$buttonWidth: 240px;
$buttonHeight: 45px;

.QuestionMaturityContainer {
  padding-right: $padding6;
  width: 370px;

  .QuestionMaturityObjective {
    color: #338ba7;
  }

  .QuestionMaturityContainer-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 16px;

    .QuestionMaturityContainer-textLeft {
      text-align: initial;

      .QuestionMaturityContainer-title {
        padding-bottom: 5px;
        font-size: 16px;
        font-weight: 500;
      }

      .QuestionMaturityContainer-textColor {
        color: #5c5c6f;
        font-size: 16px;
        font-weight: 500;
      }

      .QuestionMaturityContainer-objective {
        color: $turquoiseBlue;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .selected {
    background-color: #338ba7;
    color: $white;
  }

  .QuestionMaturityContainer-text {
    @include quickSandMedium;
    font-size: $fontSizeNormal;
    margin-top: 9px;
    text-align: left;
    color: $dolphin;
  }

  .QuestionMaturityContainer-countBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    margin-top: 3px;
    width: 33px;
    height: 33px;
    background-color: $tuna;
    border-radius: 0.125rem;
    color: $turquoiseBlue;
    @include quickSandBold;
    font-size: $fontSizeNormal;
  }
}

.QuestionMaturityRow {
  display: grid;
  grid-template-columns: 33px auto;
  margin-bottom: 4px;
  cursor: pointer;
  p {
    margin: auto auto auto 0;
  }

  .QuestionMaturityRow-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border: solid 1px #3d3d4f;
    border-radius: 2px;
    text-align: justify;
    .QuestionMaturityRow-number-of-questions {
      text-align: center;
      width: 22px;
      height: 22px;
      padding: 2px;
      border-radius: 2px;
      background-color: #373749;
      color: $white;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
