@import "~styles/colors.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";
@import "~styles/fontSizes.scss";
@import "~styles/shadows.scss";
@import "~styles/fontSizes.scss";
@import "~styles/fontFamilies.scss";

.ForgotPasswordPage {
  form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-bottom: $margin2;

    @media (min-width: 768px) {
      grid-template-columns: auto 1fr;
    }
  }

  .ForgotPasswordPage-cancel {
    font-size: $fontSizeSmall;
  }
}
