@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

.TenantUserManagementPage {
  text-align: justify;
  display: flex;
  justify-content: center;

  .TenantUserManagementPage-container {
    width: 90%;
  }

  .TenantUserManagementPage-header {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 17px 16px 15px 19px;
    background-color: $steelGray;
    margin-top: 20px;
    height: 70px;

    .title {
      color: #8ca2aa;
    }

    span {
      color: #39a1c3;
    }
  }

  .TenantUserManagementPage-body {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .TenantUserManagementPage-footer {
    margin-top: 5px;
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
    height: 80px;

    .TenantUserManagementPage-add-user-button {
      background: $steelGray3;
      border-radius: 20px;
      border: 1px solid $bostonBlue;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: 40px;
      width: 40px;
      margin-right: 20px;

      &:hover {
        background: $scooter;
        cursor: pointer;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }
  }

  .TenantUserManagementPage-input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 61px;
    margin: 2px 10px 0 0;
    padding: 17px 16px 15px 19px;
    background-color: #191924;
    border: solid 1px rgba(50, 50, 73, 0.42);
    color: $white;

    &:focus {
      outline-style: none;
    }
  }
}
