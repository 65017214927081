@import "~styles/colors.scss";
@import "~styles/fontSizes.scss";
@import "~styles/fontFamilies.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";

$buttonHeight: 46px;

.Button {
  @include quickSandMedium;
  background-color: transparent;
  border-radius: $buttonHeight / 2;
  border: solid 1px $gunPowder;
  color: $white;
  cursor: pointer;
  height: $buttonHeight;
  min-width: 180px;
  padding: $padding2;

  &.Button--primary {
    background-color: $scooter;
    border: 0;
  }
}
