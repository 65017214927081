@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

.NodeUsers-container {
  width: 100%;

  .NodeUsers-toggleCheckAll {
    background: transparent;
    color: $graySuit;
    margin-right: 5px;
    &:hover {
      color: $concret;
    }
  }

  .NodeUsers-toggleCheckAllIcon {
    font-size: 14px;
  }

  .NodeUsers-profilePic {
    border-radius: 50%;
    width: 30px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    z-index: 0;

    cursor: progress;
  }

  .NodeUsers-content-title {
    color: $gunPowder;
    &:hover {
      color: $turquoiseBlue;
      cursor: pointer;
    }
  }
  .NodeUsers-content-tag {
    display: flex;
    flex-direction: column;

    transition: ease-in-out 0.3s;
  }

  .NodeUsers-user-persona {
    margin-left: 5px;
    margin-bottom: 40px;
    font-size: 10px;
    font-weight: 500;
    color: $turquoiseBlue;
    font-variant: small-caps;
  }

  .NodeUsers-content {
    height: 100%;
    max-height: 80px;
    overflow-y: scroll;
    display: flex;
    flex-flow: wrap;

    &::-webkit-scrollbar-track {
      background-color: $vulcan;
      border: 6px solid $vulcan;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border: 6px solid $martinique;
      border-radius: 12px;
      background: $martinique;
    }
    &::-webkit-scrollbar-thumb {
      background: $martinique;
      border: 6px solid $martinique;
      border-radius: 12px;
    }

    .NodeUsers-user {
      border-radius: 5px;
      display: flex;
      align-items: center;
      height: 40px;
      margin-left: 2px;
      padding: 2px 5px 2px 5px;
      background-color: transparent;
      font-size: 12px;
      font-weight: 500;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.15);
        background-color: $ebony;
      }
      h1 {
        margin-left: 5px;
        color: $concret;
      }
      .disabled {
        color: $trout2;
      }
    }
  }
}
