@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/shadows.scss';
@import '~styles/fontFamilies.scss';

$imageSize: 150px;

.AccountFormPage {
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $padding5 $padding6;
  }

  .AccountFormPage-container {
    display: flex;
    flex-direction: column;
    height: 650px;
    width: 1400px;
    background-color: $steelGray4;
    box-shadow: $boxShadow2;
    // padding: $padding4;

    @media (min-width: 768px) {
      min-width: 500px;
    }
  }

  .AccountFormPage-header {
    width: 100%;
    align-self: center;
    justify-content: center;
    background-color: $steelGray;
    padding: 15px;
    margin-top: $margin1;
    margin-bottom: $margin1;

    h1 {
      @include quickSandSemiBold;
      font-size: $fontSizeBig;
      margin: 15px;
      display: inline-block;
    }

    a {
      float: right;
      margin-top: $margin1;
      margin-bottom: $margin1;
    }
  }

  .AccountFormPage-body {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 20px;

    .AccountFormPage-left-panel {
      display: flex;
      width: 400px;
      font-size: $fontSizeTall;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      row-gap: 15px;
      padding: 20px;
      .AccountFormPage-profile-pic {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $imageSize;
        height: $imageSize;
        color: transparent;
        position: relative;

        transition: all 0.3s ease;

        .profile-pic {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: $imageSize;
          height: $imageSize;
          position: absolute;
          object-fit: cover;
          z-index: 0;
        }
        .AccountFormPage-profile-pic-label {
          cursor: pointer;
          height: $imageSize;
          width: $imageSize;
          .AccountFormPage-camera {
            height: 35px;
            width: 35px;
          }
        }

        &:hover {
          // background-color: rgba(236, 54, 54, 0.8);
          z-index: 10000;
          transition: ease-in-out 0.3s;
          .AccountFormPage-profile-pic-label {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.7);
            z-index: 10000;
            color: $white;
            box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.35);
            transition: background-color 0.2s ease-in-out;
            border-radius: 50%;
            margin-bottom: 0;
          }
        }
        &:not(:hover) {
          transition: ease-in-out 0.3s;
        }
      }

      .AccountFormPage-role {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-width: 150px;
        height: 35px;
        padding: 10px;
        color: $concret;
        border: solid 1px rgba(50, 50, 73, 1);
        border-radius: 2px;
        background-color: $steelGray6;
        // cursor: pointer;

        &:hover {
          background-color: $martinique;
          border: solid 1px $manatee;
        }
      }

      .role-user {
        background-color: $christi;
        color: $concret;
        border: solid 1px $greenYellow;
      }

      .role-admin {
        background-color: $leanit101BlueLight4;
        color: $concret;
        border: solid 1px $leanit101BlueLight3;
      }

      .role-super {
        background-color: $templateTag;
        color: $concret;
        border: solid 1px $templateTag2;
      }

      span {
        font-size: $fontSizeTall;
        color: #39a1c3;
      }
    }
    .AccountFormPage-fields {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      row-gap: 10px;
      padding: 20px;

      @media (min-width: 768px) {
        grid-template-columns: auto 1fr;
      }

      .AccountFormPage-name {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        max-height: min-content;
      }

      .AccountFormPage-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: min-content;
        .profilePic {
          border-radius: 50%;
          width: 70px;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          object-fit: cover;
          z-index: 0;
          margin: 20px;
        }

        .AccountFormPage-label {
          display: flex;
          margin: 5px;
          margin-bottom: 10px;
          font-size: $fontSizeTall;
        }
        .AccountFormPage-user-input {
          @include quickSandSemiBold;
          display: flex;
          font-size: $fontSizeTall;
          background-color: $mirage;
          color: $manatee;
          width: 100%;
          height: 48px;
          border: solid 1px $martinique2;
          color: $cadetBlue;
          padding: 0 $padding3;
          align-items: center;
        }
      }
    }
  }
  .AccountFormPage-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 90px;
    padding-right: 40px;
    padding-bottom: 40px;
    // padding-top: 10px;

    .AccountFormPage-submit {
      @include quickSandSemiBold;
      background: $bostonBlue;
      border-radius: 40px;
      border: 1px solid #3a9db8;
      color: $white;
      font-size: $fontSizeTall;
      min-height: 50px;
      // width: auto;
      min-width: 200px;

      &:hover {
        background: $shakespeare;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }
  }
}
