@import '~styles/colors.scss';
@import '~styles/margins.scss';

.AssessmentForm {
  .AssessmentForm-container {
    display: grid;
    grid-template-columns: 2fr 4fr;
    margin: 20px 0;
    width: 100%;
    .AssessmentForm-formField {
      padding-left: 15px;
      padding-right: 15px;

      .border-red {
        animation: wiggle 1s;

        border: 1px solid $errorMessage !important;
      }
      @keyframes wiggle {
        0% {
          transform: rotate(0deg);
        }
        15% {
          transform: rotate(1deg);
        }
        25% {
          transform: rotate(-1deg);
        }
        30% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }

      .wiggle {
        display: inline-block;
        animation: wiggle 1s;
      }
      .AssessmentForm-input {
        border-bottom-color: $ebonyClay;
        border: 1px solid transparent;
        color: $cadetBlue;
        background-color: $steelGray4;
        display: block;
        text-align: center;
        width: 400px;
        padding: 13px 0 13px 0;

        &:focus {
          outline-style: none;
        }
      }

      .AssessmentForm-marginTop22 {
        margin-top: $margin9;
      }

      label {
        margin-bottom: 45px;
      }

      input {
        background-color: $mirage;
      }

      textarea {
        // height: 19rem;
        padding-left: 13px !important;
        text-align: justify !important;
        background-color: $steelGray3;
      }
    }
  }

  .AssessmentForm-marginTop22 {
    margin-top: $margin9;
  }

  label {
    // margin-bottom: 68px;
  }

  input {
    background-color: $mirage;
  }

  textarea {
    resize: none;
    height: 20rem;
    max-height: 20rem;
    padding-left: 13px !important;
    text-align: justify !important;
    background-color: $steelGray3;
  }

  .AssessmentForm-dateInputs {
    .AssessmentForm-dateLabel {
      margin-bottom: 0;
    }
  }

  .rdrNextPrevButton {
    background-color: $vulcan;
  }

  .rdrMonthAndYearPickers {
    select {
      color: $concret;
    }
  }
}

// This should be refactored 👇

// Calendar component start
.rdrCalendarWrapper {
  background: #1a1a27;
  height: 23rem;
}

.rdrDateDisplayWrapper {
  background: #1a1a2a;
}

.rdrDateInput {
  background: #1a1a2a;
}

.rdrDayNumber > span {
  color: white;
}

.rdrMonthPicker {
  color: white;
}
// Calendar component end

// Slider component start
.MuiSlider-root {
  height: 5px !important;
  padding: 5 0 39px 110 !important;
  width: 619px;
  overflow: visible;
}

.MuiSlider-rail {
  height: 5px !important;
  background-image: linear-gradient(to right, #2d73a0, #5ce7ea);
}

.MuiSlider-track {
  height: 5px !important;
  background-image: linear-gradient(to right, #2d73a0, #5ce7ea);
}

.MuiSlider-thumb {
  margin-top: -4px !important;
  background-color: #5ce6e9 !important;
  border: 2px solid white !important;
}
// Slider component end

.maturity-label {
  display: grid;
  text-align: justify;
  margin: 0;
  grid-template-columns: 1fr 1fr;
}

.maturity-level {
  width: 33px;
  height: 38px;
  padding: 12px 12px 13px 13px;
  border-radius: 2px;
  background-color: #373749;
}

.value-maturity-level {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: #5ce6e9;
}

.maturity-inputs {
  display: grid;
  grid-template-columns: 1fr 2fr 3fr;
}

.Retngulo-Arredondado-665-copiar-5 {
  width: 200px;
  height: 36px;
  border: solid 1px rgba(50, 50, 73, 0.42);
  background-color: #191924;
}

.Retngulo-Arredondado-665-copiar-26 {
  width: 393px;
  height: 36px;
  border: solid 1px rgba(50, 50, 73, 0.42);
  background-color: #191924;
}
