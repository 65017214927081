@import '../../../styles/colors.scss';
@import '~styles/fontFamilies.scss';
@import '~styles/fontSizes.scss';

.CreateQuestion-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .CreateQuestion-header {
    width: 100%;
    height: 61px;
    display: flex;
    background-color: transparent;
    border: solid 1px #242432;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    .disabled {
      color: #6a6a7f;
      font-weight: 500;
    }
    p {
      font-weight: 500;
      height: 15px;
      margin-right: 16px;
    }
  }

  .CreateQuestion-countBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    width: 33px;
    height: 33px;
    background-color: $tuna;
    border-radius: 0.125rem;
    color: $turquoiseBlue;
  }

  .CreateQuestion-countValue {
    @include quickSandBold;
    font-size: $fontSizeNormal;
    color: $turquoiseBlue;
  }

  .CreateQuestion-text {
    @include quickSandRegular;
    font-size: $fontSizeNormal;
    color: $dolphin;
  }

  textarea {
    color: $white;
    width: 100%;
    resize: none;
    height: 80px;
    font-weight: 500;
    padding: 20px 0 0 20px;
    border: solid 1px #242432;
    background-color: transparent;

    &:focus {
      outline-style: none;
    }
  }
  input {
    color: $white;
    border: solid 1px transparent;
    width: 100%;
    font-weight: 500;
    background-color: transparent;

    &:focus {
      outline-style: none;
    }
  }

  .CreateQuestion-footer {
    display: flex;
    font-weight: 500;
    align-items: center;
    justify-content: space-between;
    margin: 34px 0 34px 21px;
    span {
      color: #44bedf;
    }
    .up-in-toggle {
      input {
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        border: 0;
        overflow: hidden;
        &:checked + label {
          background: #44bedf;
          color: white;
          font-weight: 500;
          box-shadow: 0 7px 20px rgba(0, 0, 0, 0.09),
            0 6px 6px rgba(0, 0, 0, 0.13);
        }
      }
      label {
        display: inline-block;
        width: 113px;
        height: 35px;
        background-color: #252531;
        color: rgb(165, 173, 183);
        text-align: center;
        padding: 6px 6px;
        float: left;
        transition: all 0.1s ease-in-out;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .up-in-toggle label:first-of-type {
      border-radius: 15.5px 0 0 15.5px;
    }

    .up-in-toggle label:last-of-type {
      border-radius: 0 15.5px 15.5px 0;
    }
  }
  hr {
    opacity: 0.4;
    background-color: #2e2e3f;
    margin-bottom: 34px;
  }
}
