@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';

$choiceBoxSize: 33px;

.Choice-container {
  margin-top: 10px;

  .Choice-deleteButton {
    color: $graySuit;
    margin-left: 5px;
    &:hover {
      color: rgb(146, 0, 0);
      cursor: pointer;
    }
  }

  .Choice-createdChoice-container {
    height: 200px;
    overflow-y: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar-track {
      background-color: #22222e;
      border: 6px solid transparent;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border: 6px solid transparent;
      border-radius: 12px;
      background: #2a2a38;
    }
    &::-webkit-scrollbar-thumb {
      background: #2a2a38;
      border: 6px solid transparent;
      border-radius: 12px;
    }

    .Choice-createdChoice {
      width: 100%;
      min-height: 40px;
      margin-bottom: 3px;
      display: flex;
      background-color: transparent;
      border: solid 1px #242432;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      .disabled {
        color: #6a6a7f;
        font-weight: 500;
      }
      &:hover {
        background-color: $vulcan;
      }
      p {
        font-weight: 500;
        min-height: 15px;
        min-width: 160px;
        margin-right: 16px;
      }
      .Choice-countBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
        width: $choiceBoxSize;
        height: $choiceBoxSize;
        background-color: $tuna;
        border-radius: 0.125rem;
        color: $turquoiseBlue;
        .Choice-countValue {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 0px;
          padding-bottom: 0px;
        }
      }
      button {
        // width: 45px;
        background-color: transparent !important;
        border: none;
        cursor: pointer;

        &:focus {
          outline-style: none;
        }
      }
      .Choice-select {
        width: 100%;
        // min-height: 40px;
        display: flex;
        background-color: transparent;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .Choice-header {
    width: 724px;
    height: 50px;
    display: flex;
    background-color: $steelGray5;
    border: solid 1px #242432;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    .disabled {
      color: #6a6a7f;
      font-weight: 500;
    }
    p {
      font-weight: 500;
      min-height: 15px;
      margin-right: 16px;
    }
    .Choice-countBox {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      width: $choiceBoxSize;
      height: $choiceBoxSize;
      background-color: $tuna;
      border-radius: 0.125rem;
      color: $turquoiseBlue;
    }
  }
  textarea {
    color: $white;
    width: 100%;
    resize: none;
    height: 90px;
    font-weight: 500;
    padding: 20px 0 0 20px;
    border: solid 1px #242432;
    background-color: transparent;

    &:focus {
      outline-style: none;
    }
  }
  input {
    color: $white;
    border: solid 1px transparent;
    width: 100%;
    font-weight: 500;
    background-color: transparent;

    &:focus {
      outline-style: none;
    }
  }

  .MuiSlider-root {
    height: 5px !important;
    padding: 0 !important;
    width: 30%;
  }

  .MuiSlider-rail {
    height: 5px !important;
    background-image: linear-gradient(to right, #2d73a0, #5ce7ea);
  }

  .MuiSlider-track {
    height: 5px !important;
    background-image: linear-gradient(to right, #2d73a0, #5ce7ea);
  }

  .MuiSlider-thumb {
    margin-top: -4px !important;
    background-color: #5ce6e9 !important;
    border: 2px solid white !important;
  }
}
