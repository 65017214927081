@import "~styles/shadows.scss";

.AddChoice {
  width: 100%;

  .AddChoice--btn {
    width: 100%;
    height: 42px;
    margin: 10px 0px 10px 0px;
    padding: 10px 31px 10px 11px;
    border: solid 1px #3d3d4f;
    color: #82a40c;
    background-color: transparent;
    font-size: 18px;
    font-weight: 500;
    outline: none;
    span {
      color: white;
      width: 22px;
      height: 22px;
      margin-left: 10px;
    }
    &:hover {
      cursor: pointer;
      border: solid 1px #7b7b81;
      box-shadow: $boxShadow1;
    }
    &:disabled {
      color: #ffffff5e;
      span {
        color: #ffffff5e;
      }
    }
  }
}
