@import "../../../styles/colors.scss";
@import "../../../styles/fontFamilies.scss";
@import "../../../styles/fontSizes.scss";

.ObjectiveMaturityProgressBar {
  padding-top: 9px;

  .ObjectiveMaturityProgressBar-text {
    @include quickSandMedium;
    font-size: $fontSizeNormal;
    margin-bottom: 9px;
    text-align: left;
    color: $dolphin;
  }
}
