$quickSandFontFamily: "Quicksand";

@mixin quickSandThin {
  font-family: $quickSandFontFamily;
  font-weight: 100;
}

@mixin quickSandLight {
  font-family: $quickSandFontFamily;
  font-weight: 300;
}

@mixin quickSandRegular {
  font-family: $quickSandFontFamily;
  font-weight: 400;
}

@mixin quickSandMedium {
  font-family: $quickSandFontFamily;
  font-weight: 500;
}

@mixin quickSandSemiBold {
  font-family: $quickSandFontFamily;
  font-weight: 600;
}

@mixin quickSandBold {
  font-family: $quickSandFontFamily;
  font-weight: 700;
}
