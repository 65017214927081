@import "~styles/colors.scss";
@import "~styles/fontSizes.scss";
@import "~styles/fontFamilies.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";

.AnswerFormCompletePage {
  width: 100%;
  height: 100%;

  h2 {
    @include quickSandMedium;
    font-size: $fontSizeBig;
    text-align: center;
    margin-top: $margin2;
  }

  .AnswerFormCompletePage-checkYou {
    height: auto;
    @include quickSandRegular;
    font-size: $fontSizeNormal;
    color: $graySuit;
    text-align: center;
    width: 100%;
    margin-left: 0 auto;
  }
}
