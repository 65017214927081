@import "~styles/colors.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";
@import "~styles/fontSizes.scss";
@import "~styles/fontFamilies.scss";

/* Blink for Webkit and others
(Chrome, Safari, Firefox, IE, ...)
*/

$buttonWidth: 80px;
$buttonHeight: 20px;

.ObjectivesAreas {
  margin-top: 10px;
  text-align: justify;
  .ObjectivesAreas-header {
    display: flex;
    flex-direction: column;
    // flex-flow: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 17px 16px 15px 19px;

    span {
      color: #39a1c3;
    }
  }
  .ObjectivesAreas-input {
    margin: 2px 0;
    padding: $padding2;
    background-color: #191924;
    border: solid 1px rgba(50, 50, 73, 0.42);

    input {
      width: 100%;
      background-color: #191924;
      border: 1px solid transparent;
      color: $white;
      display: block;

      &:focus {
        outline-style: none;
      }
    }
  }
  .ObjectivesAreas-texarea {
    margin-top: 4px;
    textarea {
      width: 100%;
      border: solid 1px rgba(50, 50, 73, 0.42);
      padding: $padding2;
      max-height: 80px;
      color: $white;
      background-color: #191924;

      &:focus {
        outline-style: none;
      }
    }
  }

  .ObjectivesAreas-button-group {
    margin-top: 5px;
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;

    .FormButton {
      margin-right: 10px;
    }
  }
}

.blink-me {
  animation: blinker 5s ease-in-out;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
