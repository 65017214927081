@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

$buttonWidth: 80px;
$buttonHeight: 20px;

.TenantUserProfile {
  @include quickSandSemiBold;

  text-align: justify;
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  .TenantUserProfile-roles {
    display: flex;
    flex-direction: row;
    column-gap: 3px;
    width: 80%;
  }

  .TenantUserProfile-role {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 150px;
    height: 35px;
    padding: 10px;
    color: $concret;
    border: solid 1px rgba(50, 50, 73, 1);
    border-radius: 2px;
    background-color: $steelGray6;
    cursor: pointer;

    &:disabled {
      background: #48b9d824;
      color: #ffffff5e;
    }
    &:hover {
      background-color: $martinique;
      border: solid 1px $manatee;
    }
  }

  .role-user-selected {
    background-color: $christi;
    color: $concret;
    border: solid 1px $greenYellow;
  }

  .role-admin-selected {
    background-color: $leanit101BlueLight4;
    color: $concret;
    border: solid 1px $leanit101BlueLight3;
  }

  .role-super-selected {
    background-color: $templateTag;
    color: $concret;
    border: solid 1px $templateTag2;
  }

  .TenantUserProfile-container {
    width: 80%;
  }

  .TenantUserProfile-header {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    padding: 17px 16px 15px 19px;
    background-color: $steelGray;
    margin-top: 10px;
    height: 50px;

    .title {
      color: #8ca2aa;
    }

    span {
      color: #39a1c3;
    }
  }

  .TenantUserProfile-user-input {
    @include quickSandSemiBold;
    font-size: $fontSizeNormal;
    background-color: $mirage;
    color: $manatee;
    width: 80%;
    max-height: 2.5rem;
    border: solid 1px $martinique2;
    color: $cadetBlue;
    padding: 0 $padding3;
    height: 100px;
  }

  .TenantUserProfile-body {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    column-gap: 5px;
    .TenantUserProfile-leftPanel {
      padding: 20px;
      height: auto;
      width: 60%;
      border: 1px solid $steelGray3;
      background-color: $mirage;
      display: flex;
      flex-direction: column;
      //justify-content: space-between;
      .TenantUserProfile-user {
        display: flex;
        align-items: center;
        .TenantUserProfile-profilePic {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          object-fit: cover;
          z-index: 0;
          cursor: progress;
        }
        .TenantUserProfile-user-name {
          margin-left: 20px;
          @include quickSandSemiBold;
          font-size: $fontSizeTall;
          color: $manatee;
        }
      }
      .TenantUserProfile-user-data {
        margin-top: 5px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }

      .TenantUserProfile-user-field-set {
        height: 100%;
        max-height: 50px;
        min-height: 30px;
        margin: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .TenantUserProfile-user-field-title {
          @include quickSandSemiBold;
          display: flex;
          flex-wrap: wrap;
          max-width: 100px;
          margin-right: auto;
          font-size: $fontSizeNormal;
          color: $leanit101BlueLight3;
        }
        .TenantUserProfile-user-field-content {
          @include quickSandSemiBold;
          font-size: $fontSizeNormal;
          color: $concret;
        }
      }
    }
    .TenantUserProfile-rightPanel {
      padding: 20px;
      height: auto;
      width: 40%;
      // overflow: scroll;
      border: 1px solid $steelGray3;
      background-color: $mirage;
      display: flex;
      flex-direction: column;

      .TenantUserProfile-add-nodes {
        max-height: 200px;
      }
      .TenantUserProfile-nodes {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        max-width: 100%;
        max-height: 250px;
        overflow: auto;
        flex-wrap: wrap;
        align-items: center;
        .TenantUserProfile-selected-node-tag {
          display: flex;
          min-width: 45%;
          max-width: 45%;
          height: 50px;
          overflow: hidden;
          border: 5px solid $easternBlue;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          margin: 3px;
          color: #f2f2f2;
          background: $easternBlue;
          font-weight: 800;
          .node-title {
            font-size: $fontSizeSmall;
          }
          small {
            color: $gunPowder;
            font-weight: 600;
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .launch-icon {
    color: $graySuit;
    &:hover {
      color: white;
      cursor: pointer;
    }
    &:disabled {
      color: $gunPowder;
      &:hover {
        color: $gunPowder;
        cursor: not-allowed;
      }
    }
  }

  .TenantUserProfile-submit-button {
    margin-left: auto;
    background: $steelGray3;
    border-radius: $buttonWidth / 2;
    border: 1px solid $bostonBlue;
    color: $white;
    @include quickSandSemiBold;
    font-size: $fontSizeSmall;
    height: $buttonHeight;
    width: $buttonWidth;
    margin-right: 20px;

    &:hover {
      background: $scooter;
      // border: 1px solid $turquoiseBlue;
    }

    &:focus {
      outline-style: none;
    }

    &:disabled {
      background: #48b9d824;
      color: #ffffff5e;
    }
  }

  .TenantUserProfile-footer {
    margin-top: 5px;
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
    height: 60px;

    .TenantUserProfile-close-button {
      background: $steelGray3;
      border-radius: $buttonWidth / 2;
      border: 1px solid $bostonBlue;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth;
      margin-right: 20px;

      &:hover {
        background: $scooter;
        // border: 1px solid $turquoiseBlue;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }
  }
}
