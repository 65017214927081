@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/shadows.scss';

.Tree-icon {
  color: $turquoiseBlue;
  cursor: pointer;

  &:hover {
    color: $pelorous;
  }
}

.showAverage {
  color: $concret;
}

.pageContentHeader {
  background-color: $astral1;
  // box-shadow: $boxShadow4;
}

.winner-title {
  color: $greenYellow;
  margin-right: 10px;
  @include quickSandSemiBold;
  font-size: $fontSizeSmall;
  font-weight: 600;
}

.winner-desc {
  color: $concret;
  @include quickSandSemiBold;
  font-size: $fontSizeSmall;
  font-weight: 400;
}

.closeSideBarButton {
  color: rgb(255, 255, 255);
  padding-top: 20px;
  padding-right: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.85rem;
  font-variant-caps: all-small-caps;
  margin: 2px;
  cursor: pointer;
}

.open-matrix-button {
  display: flex;
  align-items: center;
  color: $concret;
  padding: 8px;

  &:hover {
    color: $graySuit;
  }
}

/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

body {
  overflow-x: hidden;
}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
}

#wrapper.toggled {
  padding-left: 500px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 500px;
  width: 0;
  height: 91%;
  margin-left: -500px;
  background: -webkit-linear-gradient(50deg, $vulcan, $mirage);
  background: linear-gradient(40deg, $vulcan, $mirage);
  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    background-color: #22222e;
    border: 6px solid transparent;
    border-radius: 12px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    border: 6px solid transparent;
    border-radius: 12px;
    background: #2a2a38;
  }
  &::-webkit-scrollbar-thumb {
    background: #2a2a38;
    border: 6px solid transparent;
    border-radius: 12px;
  }
}

#wrapper.toggled #sidebar-wrapper {
  width: 500px;
}

#page-content-wrapper {
  width: 100%;
  padding-top: 15px;
  position: absolute;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -500px;
}

/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 500px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 0;
  }
  #wrapper.toggled {
    padding-left: 500px;
  }
  #sidebar-wrapper {
    width: 0;
  }
  #wrapper.toggled #sidebar-wrapper {
    width: 500px;
  }
  #page-content-wrapper {
    position: relative;
  }
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}
