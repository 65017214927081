@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';

.rootTree {
  // width: fit-content;
  // height: fit-content;
  // background-color: $steelGray4;
  .collapse-icon {
    padding: 10px;
  }
  .search-bar {
    padding: 0px;
  }

  .toolbar {
    z-index: 10;

    align-items: center;
    display: flex;
    position: sticky;
    top: 10px;
    // background-color: $mirage;
    // width: 100%;
    // width: 400px;
    min-height: 50px;
    padding: 10px;
    margin: 10px 10px;
    border: 1px solid rgba(50, 68, 73, 0.42);
    border-radius: 2px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: row;
    background-color: $ebony;
    box-shadow: 2px 2px 6px #00000088;
    .toolbar-item {
      border-radius: 5px;
      display: flex;
      padding: 5px;
      height: fit-content;
      &:hover {
        background-color: $steelGray;
        box-shadow: 1px 1px 3px #0000002a;
      }
      &:active {
        background-color: $steelGray3;
        box-shadow: inset 1px 1px 3px #0000001a;
        color: $graySuit;
      }
    }
    .enabled {
      color: $turquoiseBlue;
    }
    .toolbar-disabled {
      padding: 5px;
      color: $comet;
    }
  }
}
