@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';
@import '~styles/shadows.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
.Master {
  width: 1000px;
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Master-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: min-content;
    width: 1400px;
    background-color: $steelGray4;
    box-shadow: $boxShadow2;

    @media (min-width: 768px) {
      min-width: 500px;
    }

    .Master-role {
      display: flex;
      margin-top: 15px;
      justify-content: center;
      align-items: center;
      width: 56px;
      height: 14px;
      padding: 2px;
      font-size: 8px;
      color: $concret;
      border: solid 1px rgba(50, 50, 73, 1);
      border-radius: 2px;
      background-color: $steelGray6;
      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
      &:hover {
        background-color: $martinique;
        border: solid 1px $manatee;
      }
    }

    .Master-header {
      width: 100%;
      align-self: center;
      justify-content: center;
      background-color: $steelGray;
      padding: 15px;

      h1 {
        @include quickSandSemiBold;
        font-size: $fontSizeBig;
        margin: 15px;
        display: inline-block;
      }
    }
    .Master-body {
      display: flex;
      background-color: $steelGray4;
      height: min-content;
      padding: 20px;
      column-gap: 20px;
      @media (min-width: 768px) {
        grid-template-columns: auto 1fr;
      }

      .Master-list-orgs {
        display: flex;
        background-color: $steelGray3;
        flex-direction: column;
        // flex-wrap: wrap;
        width: 70%;
        height: 500px;
        box-shadow: $boxShadow2;
        @media (min-width: 768px) {
          grid-template-columns: auto 1fr;
        }

        .Master-list-orgs-header {
          font-size: $fontSizeBig;
          display: flex;
          flex-direction: column;
          background-color: $steelGray2;
          padding: 20px;
          width: 100%;
        }

        .Master-organizations {
          overflow: auto;
          display: flex;
          padding: 20px;
          max-width: 100%;
          height: 100%;
          max-height: 100%;
          // flex-wrap: wrap;
          flex-direction: column;
          .Master-organization {
            height: min-content;
            // flex-wrap: wrap;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // height: s;
            // width: s;
            margin: 5px;
            padding: 10px 15px 10px 15px;
            background-color: $steelGray4;
            font-size: 20px;
            font-weight: 500;
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            &:hover {
              transform: scale(1.02);
              background-color: $leanit101BlueLight4;
            }
            h1 {
              color: $concret;
            }
            .disabled {
              color: $trout2;
            }

            .Master-organization-name {
              font-weight: 600;
              color: $white;
              width: 100%;
              margin: 10px;
            }
            .Master-organization-email {
              font-size: 12px;
              font-weight: 500;
              color: $turquoiseBlue;
              width: 100%;
              margin: 10px;
            }
          }
        }
      }

      .Master-add-org {
        display: flex;
        flex-direction: column;
        background-color: $steelGray3;
        width: 30%;
        height: 500px;
        align-items: flex-start;
        box-shadow: $boxShadow2;
        // justify-content: center;
        .Master-add-org-header {
          font-size: $fontSizeBig;
          display: flex;
          flex-direction: column;
          background-color: $steelGray2;
          padding: 20px;
          width: 100%;
        }
        .Master-fields {
          width: 100%;
          display: flex;
          height: 100%;
          flex-direction: column;
          // flex-wrap: wrap;
          align-items: flex-end;
          row-gap: 10px;
          padding: 20px;
          // background-color: $steelGray;

          @media (min-width: 768px) {
            grid-template-columns: auto 1fr;
          }

          .Master-submit-org {
            @include quickSandSemiBold;
            margin: 5px;
            background: $bostonBlue;
            border-radius: 40px;
            border: 1px solid #3a9db8;
            color: $white;
            font-size: $fontSizeTall;
            width: 100px;
            height: 30px;
            justify-content: center;
            align-items: center;
            &:hover {
              background: $shakespeare;
            }

            &:focus {
              outline-style: none;
            }

            &:disabled {
              background: #48b9d824;
              color: #ffffff5e;
            }
          }

          .Master-name {
            display: flex;
            flex-direction: row;
            column-gap: 10px;
            max-height: min-content;
          }

          .Master-row {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            max-height: min-content;

            .Master-label {
              display: flex;
              margin: 5px;
              margin-bottom: 10px;
              font-size: $fontSizeTall;
            }
            .Master-user-input {
              @include quickSandSemiBold;
              display: flex;
              font-size: $fontSizeTall;
              background-color: $mirage;
              color: $manatee;
              width: 100%;
              height: 48px;
              border: solid 1px $martinique2;
              color: $cadetBlue;
              padding: 0 $padding3;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
