@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

/* Blink for Webkit and others
(Chrome, Safari, Firefox, IE, ...)
*/

$buttonWidth: 80px;
$buttonHeight: 20px;

.ManageUsers {
  text-align: justify;
  display: flex;
  justify-content: center;

  .ManageUsers-container {
    width: 100%;
  }

  .ManageUsers-header {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 17px 16px 15px 19px;
    background-color: $steelGray;
    margin-top: 20px;
    height: 70px;

    .title {
      color: #8ca2aa;
    }

    span {
      color: #39a1c3;
    }
  }

  .ManageUsers-body {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    // height: 600px;
    display: flex;
    flex-direction: row;
  }

  .NodeUsers-content {
    height: 180px;
    max-height: 200px;
  }

  .ManageUsers-left-panel {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .ManageUsers-right-panel {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .ManageUsers-nodes-list {
    height: 240px;

    .ManageUsers-nodes-list-content {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      height: 188px;
      overflow-y: scroll;
      width: 100%;

      &::-webkit-scrollbar-track {
        background-color: $vulcan;
        border: 6px solid $vulcan;
        border-radius: 12px;
      }
      &::-webkit-scrollbar {
        width: 6px;
        border: 6px solid $martinique;
        border-radius: 12px;
        background: $martinique;
      }
      &::-webkit-scrollbar-thumb {
        background: $martinique;
        border: 6px solid $martinique;
        border-radius: 12px;
      }
    }
  }

  .ManageUsers-selected-node-tag {
    display: flex;
    width: 170px;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 3px;
    color: #f2f2f2;
    background: $easternBlue;
    font-weight: 800;
    .node-title {
      font-size: $fontSizeSmall;
    }
    small {
      color: $gunPowder;
      font-weight: 600;
      font-size: 0.8rem;
    }
  }

  .ManageUsers-section-title {
    display: flex;
    justify-content: space-between;
    @include quickSandSemiBold;
    font-size: $fontSizeBig;
    color: $graySuit;
    margin-bottom: 20px;

    .no-wrap {
      width: 500px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ManageUsers-role {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 31%;
    height: 40px;
    padding: 10px;
    color: $concret;
    border: solid 1px rgba(50, 50, 73, 1);
    border-radius: 2px;
    background-color: $steelGray6;
    cursor: pointer;
    &:disabled {
      background: #48b9d824;
      color: #ffffff5e;
    }
    &:hover {
      background-color: $martinique;
      border: solid 1px $manatee;
    }
  }

  .role-user-selected {
    background-color: $christi;
    color: $concret;
    border: solid 1px $greenYellow;
  }

  .role-admin-selected {
    background-color: $leanit101BlueLight4;
    color: $concret;
    border: solid 1px $leanit101BlueLight3;
  }

  .role-super-selected {
    background-color: $templateTag;
    color: $concret;
    border: solid 1px $templateTag2;
  }

  .launch-icon {
    color: $graySuit;
    &:hover {
      color: white;
      cursor: pointer;
    }
    &:disabled {
      color: $gunPowder;
      &:hover {
        color: $gunPowder;
        cursor: not-allowed;
      }
    }
  }

  .ManageUsers-profilePic {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    z-index: 0;
  }

  .picContainer {
    margin-right: 10px;
    width: 30px;
  }

  .please-select {
    display: flex;
    height: 40px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
  }

  .ManageUsers-input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    // margin: 2px 10px 0 0;
    // padding: 17px 16px 15px 19px;
    padding-right: 10px;
    background-color: #191924;
    border: solid 1px rgba(50, 50, 73, 0.42);
    margin-top: 10px;

    input {
      width: 100%;
      background-color: #191924;
      border: 1px solid transparent;
      color: $white;
      display: block;

      &:focus {
        outline-style: none;
      }
    }
  }

  .ManageUsers-button-group {
    margin-top: 5px;
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
    height: 60px;

    .ManageUsers-updateButton {
      background: $olivetone;
      margin-right: 10px;
      border-radius: $buttonWidth / 2;
      border: none;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth * 2;

      &:hover {
        background: $greenYellow3;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }

    .ManageUsers-cancelButton {
      background: $steelGray3;
      border-radius: $buttonWidth / 2;
      border: 1px solid $bostonBlue;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth;

      &:hover {
        background: $scooter;
        // border: 1px solid $turquoiseBlue;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }
  }

  .ManageUsers-not-found {
    @include quickSandSemiBold;
    font-size: $fontSizeNormal;
    color: $manatee;
    padding: 10px;
    font-weight: 400;
  }

  .ManageUsers-invited-users-content {
    margin-top: 20px;
    height: 400px;
    overflow-y: scroll;
    width: 100%;

    &::-webkit-scrollbar-track {
      background-color: $vulcan;
      border: 6px solid $vulcan;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border: 6px solid $martinique;
      border-radius: 12px;
      background: $martinique;
    }
    &::-webkit-scrollbar-thumb {
      background: $martinique;
      border: 6px solid $martinique;
      border-radius: 12px;
    }
  }

  .ManageUsers-selected-card {
    @include quickSandSemiBold;
    background-color: transparent;
    font-size: $fontSizeSmall;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
    margin-top: 3px;
    border: solid 1px rgba(10, 205, 253, 0.5);
    border-radius: 2px;

    &:first-child {
      margin-top: 20px;
    }

    &:hover {
      background-color: $vulcan;
    }

    .ManageUsers-selected-card-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .selected-name {
        @include quickSandSemiBold;
        font-size: $fontSizeNormal;
        color: $graySuit;
      }
      .selected-email {
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        color: $iron;
        width: 60%;
      }
      .selected-persona {
        @include quickSandBold;
        font-size: $fontSizeSmall;
        color: white;
        width: 100px;
        text-align: center;
        padding: 2px 6px 2px 6px;
        border-radius: 2px;
        background-color: $leanit101BlueLight2;
      }
      .selected-role {
        @include quickSandSemiBold;
        font-size: $fontSizeNormal;
        text-align: center;
        color: $greenYellow;
        margin: 0 auto;
        padding: 0px;
        text-transform: uppercase;
      }
      .selected-info {
        display: flex;
        margin-top: 5px;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
