@import "~styles/colors.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";
@import "~styles/fontSizes.scss";

$progressBarHeight: 3px;
$buttonWidth: 240px;
$buttonHeight: 45px;

.Steps {
  background-color: $steelGray4;

  .Steps-top {
    color: $trout;
    padding: 0 $padding6;
  }

  .Steps-tab {
    font-size: 20px;
    margin-top: 43px;
    display: inline-block;

    span {
      cursor: pointer;
    }
  }

  .selected {
    span {
      color: white;
    }
  }
  
  .Steps-progress {
    margin: $margin5 $margin6;
    height: $progressBarHeight;
    background-color: $charade;

    .Steps-progressBar {
      height: $progressBarHeight;
      width: 50%;
      background: linear-gradient(to top, #2d73a0, #5ce7ea);
    }
  }

  .Steps-loadTemplateButton {
    background: $steelGray4;
    border-radius: $buttonWidth / 2;
    border: solid 1px rgba(61, 61, 79, 0.52);
    color: $white;
    font-size: $fontSizeBig;
    height: $buttonHeight;
    margin: 0px 13px 22px 0px;
    width: $buttonWidth;
    cursor: pointer;

    &:focus {
      outline-style: none;
    }
  }

  .Steps-nextButton {
    background: $bostonBlue;
    border-radius: $buttonWidth / 2;
    border: none;
    color: $white;
    font-size: $fontSizeBig;
    height: $buttonHeight;
    width: $buttonWidth;

    &:hover {
      background: $shakespeare;
    }

    &:focus {
      outline-style: none;
    }

    &:disabled {
      background: #48b9d824;
      color: #ffffff5e;
    }
  }
}
