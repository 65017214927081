@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

$buttonWidth: 100px;
$buttonHeight: 25px;

.OrganizationProfile {
  @include quickSandSemiBold;

  text-align: justify;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  .OrganizationProfile-container {
    background-color: $mirage;
    width: 70%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    .OrganizationProfile-header {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      padding: 20px;
      background-color: $steelGray;
      font-size: $fontSizeTall;
      // margin-top: 10px;
      min-height: 60px;

      .title {
        color: #8ca2aa;
      }

      span {
        color: #39a1c3;
      }
    }

    .OrganizationProfile-body {
      display: flex;
      padding: 20px;
      height: 100%;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      row-gap: 5px;

      .OrganizationProfile-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        // margin: 5px;
        // padding: 5px;
        font-size: $fontSizeTall;
        height: 100%;
        .OrganizationProfile-user-field-title {
          color: $leanit101BlueLight3;
        }
        .OrganizationProfile-input {
          display: flex;
          @include quickSandSemiBold;
          font-size: $fontSizeNormal;
          background-color: $mirage;
          color: $manatee;
          width: 300px;
          max-height: 2.5rem;
          border: solid 1px $martinique2;
          color: $cadetBlue;
          padding: 0 $padding3;
          height: 30px;
        }
        .OrganizationProfile-submit {
          margin-left: auto;
          background: $steelGray3;
          border-radius: 5px;
          border: 1px solid $bostonBlue;
          color: $white;
          @include quickSandSemiBold;
          font-size: $fontSizeSmall;
          height: 30px;
          width: 30px;
          &:hover {
            background: $scooter;
            // border: 1px solid $turquoiseBlue;
          }

          &:focus {
            outline-style: none;
          }

          &:disabled {
            background: #48b9d824;
            color: #ffffff5e;
          }
        }
      }
    }

    .OrganizationProfile-footer {
      display: flex;
      padding: 20px;
      justify-content: flex-end;
      align-items: center;
      min-height: 60px;
      column-gap: 10px;

      .OrganizationProfile-close-button,
      .OrganizationProfile-submit-button {
        background: $steelGray3;
        border-radius: $buttonWidth / 2;
        border: 1px solid $bostonBlue;
        color: $white;
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        height: $buttonHeight;
        width: $buttonWidth;
        // margin-right: 20px;

        &:hover {
          background: $scooter;
          // border: 1px solid $turquoiseBlue;
        }

        &:focus {
          outline-style: none;
        }

        &:disabled {
          background: #48b9d824;
          color: #ffffff5e;
        }
      }
    }
  }
}
