@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';
@import '~styles/shadows.scss';

.TenantTemplate-container {
  width: 100%;
  padding: 5px;
  padding-top: 10px;

  .TenantTemplate-content {
    min-height: 500px;
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    max-width: 100%;
    padding-right: 3px;

    &::-webkit-scrollbar-track {
      background-color: $vulcan;
      border: 6px solid $vulcan;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 8px;
      border: 6px solid $martinique;
      border-radius: 12px;
      background: $martinique;
    }
    &::-webkit-scrollbar-thumb {
      background: $martinique;
      border: 6px solid $cinder;
      border-radius: 12px;
    }

    .TenantTemplate-template {
      position: static;
      @include quickSandSemiBold;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 60px;
      width: 98%;
      margin: 0px 5px 10px 5px;
      padding: 4px 5px 4px 15px;
      background-color: $steelGray4;
      font-size: 12px;
      font-weight: 500;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      outline: none;
      border: none;
      color: white;

      &:hover {
        background-color: $leanit101BlueLight4;
      }
      &:focus {
        transform: scale(1.02);
        background-color: $leanit101BlueLight4;
        .TenantTemplate-options {
          display: inline;
        }
      }
      h1 {
        color: $concret;
      }
      .disabled {
        color: $trout2;
      }

      .TenantTemplate-template-name {
        position: static;
        width: fit-content;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        z-index: 1;
      }
      .deployed-tag {
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        font-weight: 600;
        flex-shrink: 0;
        width: max-content;
        padding-right: 5px;
        padding-left: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 18px;
        position: relative;
        background: $templateTag;
        margin-right: 15px;
        color: white;
      }
      .deployed-tag:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 9px solid $steelGray4;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
      }
      .deployed-tag:before {
        content: '';
        position: absolute;
        right: -9px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 9px solid $templateTag;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
      }
    }
  }
}
