@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/shadows.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

.FormPage {
  height: 90vh;
  width: 100vw;

  label {
    margin: auto 0;
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $padding5 $padding6;
  }

  .FormPage-container {
    padding: 0;
    background-color: $steelGray4;
    box-shadow: $boxShadow2;
    padding: $padding4;

    @media (min-width: 768px) {
      padding: $padding5;
      min-width: 500px;
    }
  }

  .FormPage-header {
    align-self: center;
    justify-content: center;
    h1 {
      @include quickSandSemiBold;
      font-size: $fontSizeGiant;
      margin-top: $margin1;
      display: inline-block;
      margin-bottom: $margin1;
    }

    a {
      float: right;
      margin-top: $margin2;
      margin-bottom: $margin2;
    }
  }

  .FormPage-errors,
  .FormPage-successMessages {
    margin: $margin1 0;
    padding: $padding4;
    @include quickSandMedium;

    li {
      margin: 5px 0;
    }
  }

  .FormPage-errors {
    background-color: $errorBackground;
  }

  .FormPage-successMessages {
    background-color: $christi;
  }

  p {
    margin-top: $margin2;
    font-size: $fontSizeSmall;
  }

  input[type='submit'] {
    margin-top: $margin1;
    height: 30px;
  }
}
