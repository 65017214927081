@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';

$buttonWidth: 240px;
$buttonHeight: 45px;

.QuestionList-container {
  width: 400px;

  .QuestionList-objective {
    color: #338ba7;
  }

  .QuestionList-header {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    width: 100%;
    padding: 17px 16px 15px 19px;
    background-color: #242432;
    font-size: 14px;
    font-weight: 500;
    .QuestionList-number-of-questions {
      text-align: center;
      width: 22px;
      height: 22px;
      padding: 2px;
      border-radius: 2px;
      background-color: #373749;
      color: $white;
      font-size: 16px;
      font-weight: 500;
    }
    .QuestionList-textLeft {
      text-align: initial;

      .QuestionList-title {
        padding-bottom: 5px;
        font-size: 16px;
        font-weight: 500;
      }

      .QuestionList-textColor {
        color: #5c5c6f;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .QuestionList-addButton {
    background-color: transparent;
    border-radius: $buttonWidth / 2;
    border: none;
    color: $white;
    cursor: pointer;
    &:focus {
      outline-style: none;
    }

    .QuestionList-icon {
      min-width: 32px;
      color: $white;
    }
  }

  .QuestionList-content {
    height: 500px;
    overflow-y: scroll;
    &::-webkit-scrollbar-track {
      background-color: #22222e;
      border: 6px solid transparent;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border: 6px solid transparent;
      border-radius: 12px;
      background: #2a2a38;
    }
    &::-webkit-scrollbar-thumb {
      background: #2a2a38;
      border: 6px solid transparent;
      border-radius: 12px;
    }
    .QuestionList-deleteButton {
      color: $graySuit;
      &:hover {
        color: rgb(146, 0, 0);
        cursor: pointer;
      }
    }
    .QuestionList-question {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 61px;
      margin: 0px 10px 0 0;
      padding: 17px 16px 15px 19px;
      background-color: transparent;
      border: solid 1px #242432;
      font-size: 14px;
      font-weight: 500;
      &:hover {
        background-color: $vulcan;
        cursor: pointer;
      }
      h1 {
        margin-left: 5px;
        color: $concret;
      }
      button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

    .selected {
      display: flex;
      background: #237d97;
      color: #ffffff;
      border-left: 5px solid #72c1db !important;
      align-items: center;
      width: 100%;
      min-height: 61px;
      padding: 13px 16px 19px 19px;
      opacity: 1;
      border: solid 1px $gunPowder;

      h1 {
        color: white;
      }
    }
  }
}
