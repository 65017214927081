@import "~styles/colors.scss";
@import "~styles/fontSizes.scss";
@import "~styles/fontFamilies.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";

.AnswerForm {
  height: 100%;
  @include quickSandLight;

  @media (min-width: 1024px) {
    margin: 0 300px;
  }

  .AnswerForm-header,
  .AnswerForm-body,
  .AnswerForm-footer,
  .AnswerForm-errors {
    padding: $padding4 0;
    background-color: $steelGray3;
  }

  h1 {
    @include quickSandThin;
    font-size: $fontSizeGiant;
    text-align: left;
    margin-top: 20px;
    margin-left: 20px;
    color: $graySuit;
    text-transform: uppercase;
  }

  .AnswerForm-assessment-name {
    @include quickSandSemiBold;
    font-size: $fontSizeBig;
    text-overflow: visible;
    text-align: left;
    margin-left: 20px;
    margin-top: 10px;
    color: #70dafd;
  }
  .AnswerForm-assessment-date {
    @include quickSandSemiBold;
    font-size: $fontSizeSmall;
    text-align: right;
    width: 100%;
    margin-top: 30px;
    color: $concret;
  }

  .AnswerForm-assessment-node {
    @include quickSandSemiBold;
    font-size: $fontSizeSmall;
    text-align: left;
    width: 100%;
    margin-top: 30px;
    margin-left: 20px;
    color: $iron;
  }

  .AnswerForm-assessment-date-dates {
    @include quickSandSemiBold;
    font-size: $fontSizeSmall;
    text-align: right;
    width: 100%;
    margin-top: 5px;
    color: $graySuit;
  }

  .AnswerForm-loading {
    height: 30vh;
    display: flex;
    align-items: center;
  }

  .AnswerForm-header {
    display: flex;
    background-color: $steelGray3;
    height: 15vh;
    padding-left: $padding3;
    padding-right: $padding3;
    margin-top: $margin2;
  }

  .AnswerForm-header-left {
    display: flex;
    flex-direction: column;
    flex-grow: 0.7;
  }

  .AnswerForm-header-right {
    display: flex;
    flex-direction: column;
    flex-grow: 0.3;
    padding-right: 40px;
  }

  .AnswerForm-errors {
    margin: $margin1 0;
    padding: $padding4;
    background-color: $errorBackground;
    @include quickSandMedium;

    h2 {
      @include quickSandMedium;
      font-size: $fontSizeBig;
    }

    hr {
      background-color: $white;
    }

    ul {
      margin: $margin1 0 0 $margin1;
      list-style: disc;

      li {
        margin-top: 3px;
      }
    }
  }

  .AnswerForm-content {
    display: flex;
    flex-direction: column;
  }

  .AnswerForm-body {
    padding-top: 0px;
    p {
      margin: $margin1 0;
    }
  }

  .AnswerForm-footer {
    padding-top: $padding1;
    // margin-top: 30px;
  }

  .AnswerForm-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: $margin1 10%;

    @media (max-width: 435px) {
      margin: $margin1 0;
    }

    .ProgressBar {
      width: 50%;
      margin: 0 $margin1;
    }
  }

  .AnswerForm-cta {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;

    .Button {
      margin: 5px;
    }
  }
}
