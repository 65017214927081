@import "~styles/colors.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";
@import "~styles/fontSizes.scss";

$progressBarHeight: 3px;
$buttonWidth: 240px;
$buttonHeight: 45px;

.ModalHeader {
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $steelGray4;

  .ModalHeader-top {
    color: $trout;
    padding: $padding6;
  }

  .ModalHeader-fixed-tab {
    width: 700px;
    border-bottom: 3px solid $scooter;
  }
  .ModalHeader-tab {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    span {
      color: #f2f2f2;
      cursor: pointer;
    }
  }

  .selected {
    span {
      color: white;
    }
  }
}
