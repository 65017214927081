body {
  font-size: 16px; // Default font size for all application (1rem)
}

$fontSizeTiny: 0.5rem; // 12px
$fontSizeSmall: 0.75rem; // 12px
$fontSizeNormal: 1rem; // 16px
$fontSizeTall: 1.15rem; // 18px
$fontSizeBig: 1.3rem; // 20px
$fontSizeGiant: 1.5rem; // 24px
