@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

/* Blink for Webkit and others
(Chrome, Safari, Firefox, IE, ...)
*/

$buttonWidth: 80px;
$buttonHeight: 20px;

.BranchMaturity {
  text-align: justify;
  display: flex;
  justify-content: center;

  .BranchMaturity-container {
    width: 100%;
  }

  .BranchMaturity-header {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 17px 16px 15px 19px;
    background-color: $steelGray;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    height: 70px;

    span {
      color: #39a1c3;
    }
    .template-title {
      color: #8ca2aa;
    }
  }

  .BranchMaturity-body {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    height: 650px;
    display: flex;
    flex-direction: row;
  }

  .BranchMaturity-filter {
    justify-content: center;
    height: 410px;
  }

  .section-title {
    display: flex;
    padding: 10px;
    height: 43px;
    color: $graySuit;
  }

  .launch-icon {
    color: $graySuit;
    &:hover {
      color: white;
      cursor: pointer;
    }
    &:disabled {
      color: $gunPowder;
      &:hover {
        color: $gunPowder;
        cursor: not-allowed;
      }
    }
  }

  .BranchMaturity-filter-results {
    width: 20em;
    margin-left: 20px;
    margin-right: 10px;
  }

  .BranchMaturity-filter-results-content {
    height: 16em;
    overflow-y: scroll;
    width: 100%;

    &::-webkit-scrollbar-track {
      background-color: $vulcan;
      border: 6px solid $vulcan;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border: 6px solid $martinique;
      border-radius: 12px;
      background: $martinique;
    }
    &::-webkit-scrollbar-thumb {
      background: $martinique;
      border: 6px solid $martinique;
      border-radius: 12px;
    }
  }

  .BranchMaturity-radar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
  }

  .BranchMaturity-select-data {
    @include quickSandBold;
    font-size: $fontSizeBig;
    color: $concret;
    display: flex;
    flex-direction: column;

    .data-selected {
      margin-top: 10px;
      @include quickSandSemiBold;
      font-size: $fontSizeNormal;
      color: $greenYellow;
    }
    .data-not-selected {
      margin-top: 10px;
      @include quickSandSemiBold;
      font-size: $fontSizeNormal;
      color: $dolphin;
    }
  }

  .BranchMaturity-countBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 22px;
    width: 30px;
    height: 30px;
    background-color: $tuna;
    border-radius: 0.125rem;
    color: $turquoiseBlue;
  }

  .BranchMaturity-countValue {
    @include quickSandBold;
    font-size: $fontSizeSmall;
    color: $turquoiseBlue;
  }

  .BranchMaturity-selected-assessments {
    height: 20.2em;
    width: 20em;
    margin: 0 auto;
    margin-top: 20px;
  }

  .BranchMaturity-selected-assessments-content {
    height: 17em;
    overflow-y: scroll;
    width: 100%;

    &::-webkit-scrollbar-track {
      background-color: $vulcan;
      border: 6px solid $vulcan;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border: 6px solid $martinique;
      border-radius: 12px;
      background: $martinique;
    }
    &::-webkit-scrollbar-thumb {
      background: $martinique;
      border: 6px solid $martinique;
      border-radius: 12px;
    }
  }
  .BranchMaturity-persona-list {
    width: 340px;
    height: 10em;
  }
  .BranchMaturity-persona-list-content {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 7em;
    justify-content: space-between;
    align-items: flex-start;
    overflow-y: scroll;
    margin-top: 10px;

    &::-webkit-scrollbar-track {
      background-color: $vulcan;
      border: 6px solid $vulcan;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border: 6px solid $martinique;
      border-radius: 12px;
      background: $martinique;
    }
    &::-webkit-scrollbar-thumb {
      background: $martinique;
      border: 6px solid $martinique;
      border-radius: 12px;
    }
  }

  .BranchMaturity-selected-persona-tag {
    display: flex;
    width: 155px;
    height: 30px;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 3px;
    color: #f2f2f2;
    background: $royalPurple;
    font-weight: 800;
    .persona-title {
      font-size: $fontSizeSmall;
    }
    small {
      color: $gunPowder;
      font-weight: 600;
      font-size: 0.8rem;
    }
  }

  .BranchMaturity-not-found {
    @include quickSandSemiBold;
    font-size: $fontSizeNormal;
    color: $manatee;
    padding: 10px;
    font-weight: 400;
  }

  .BranchMaturity-assessment-card {
    @include quickSandSemiBold;
    background-color: #237d97;
    font-size: $fontSizeSmall;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 1px;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #20748b;
    }

    .BranchMaturity-assessment-card-content {
      display: flex;
      flex-direction: column;
      .node-name {
        @include quickSandBold;
        font-size: $fontSizeSmall;
        margin-top: 0.5em;
        color: #adcfe0;
        padding: 1px 6px 1px 6px;
        border-radius: 10px;
        background-color: #005771;
      }
      .node-type {
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        margin-top: 0.5em;
        color: #002744;
        margin-left: 10px;
      }
      .assessment-name {
        @include quickSandSemiBold;
        font-size: $fontSizeNormal;
        color: $manatee;
        color: white;
      }
    }
  }

  .BranchMaturity-assessment-selected-card {
    @include quickSandSemiBold;
    background-color: transparent;
    font-size: $fontSizeSmall;
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
    margin: 1px;
    margin-right: 10px;
    margin-left: 10px;
    border: solid 1px rgba(10, 205, 253, 0.5);
    border-radius: 2px;

    &:hover {
      background-color: $vulcan;
    }

    .BranchMaturity-assessment-selected-card-content {
      display: flex;
      flex-direction: column;
      .selected-node-name {
        @include quickSandBold;
        font-size: $fontSizeSmall;
        margin-top: 0.5em;
        color: white;
        padding: 1px 6px 1px 6px;
        border-radius: 10px;
        background-color: $comet;
      }
      .selected-node-type {
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        margin-top: 0.5em;
        color: #8cc2ec;
        margin-left: 10px;
      }
      .selected-assessment-name {
        @include quickSandSemiBold;
        font-size: $fontSizeNormal;
        color: $manatee;
        color: white;
      }
    }
  }

  .BranchMaturity-button-group {
    margin-top: 5px;
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
    height: 60px;

    .BranchMaturity-triggerButton {
      background: $bostonBlue;
      margin-right: 10px;
      border-radius: $buttonWidth / 2;
      border: none;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth * 1.9;

      &:hover {
        background: $shakespeare;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }

    .BranchMaturity-cancelButton {
      background: $steelGray3;
      border-radius: $buttonWidth / 2;
      border: 1px solid $bostonBlue;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth;
      margin-right: 20px;

      &:hover {
        background: $scooter;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }

    .BranchMaturity-clearButton {
      background: $steelGray3;
      border-radius: $buttonWidth / 2;
      border: 1px solid $clearButton;
      color: $white;
      @include quickSandSemiBold;
      font-size: $fontSizeSmall;
      height: $buttonHeight;
      width: $buttonWidth * 1.5;
      margin-right: 20px;

      &:hover {
        background: $clearButton;
      }

      &:focus {
        outline-style: none;
      }

      &:disabled {
        background: #48b9d824;
        color: #ffffff5e;
      }
    }
  }
}
