@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

$buttonWidth: 240px;
$buttonHeight: 45px;

.Area-header {
  @include quickSandSemiBold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 5px;
  // opacity: 0.38;
  border: solid 1px $gunPowder;
  &:hover {
    color: #ffffff;
    background-color: $steelGray6;
    cursor: pointer;
  }
}

.Area-icon {
  min-width: 20px;
  color: #c8c8c8;
  margin-right: 5px;
  &:hover {
    color: #ffffff;
    cursor: pointer;
  }
}

.Area-header--selected {
  @include quickSandSemiBold;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #237d97;
  color: #ffffff;
  border-left: 5px solid #72c1db !important;
  height: 40px;
  padding: 5px;
  opacity: 1;
  border: solid 1px $gunPowder;
}

.area-color {
  border-radius: 20%;
  margin: 10px;
  opacity: 1;
  height: 20px;
  width: 20px;
}
