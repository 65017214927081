@import '~styles/colors.scss';
@import '~styles/paddings.scss';
@import '~styles/margins.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';

.FormTextInput {
  border-bottom-color: $ebonyClay !important;
  border: 1px solid transparent;
  color: $cadetBlue;
  background-color: $steelGray4;
  display: inline-block;
  padding: $padding3 0;

  &:focus {
    outline-style: none;
  }
}
