@import "~styles/colors.scss";
@import "~styles/fontSizes.scss";
@import "~styles/fontFamilies.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";

.AnswerFormQuestion {
  margin-top: $margin1;
  background-color: $martinique;

  .AnswerFormQuestion-header {
    @include quickSandSemiBold;
    padding: $padding3;
    background-color: $martinique;

    .question-title {
      padding: 0 $padding3;
      @include quickSandSemiBold;
      font-size: $fontSizeNormal;
      color: $concret;
    }
    .question-description {
      margin: $margin1 0;
      padding: 0 $padding3;
      @include quickSandLight;
      font-size: $fontSizeNormal;
      color: $cadetBlue;
    }
  }
}
