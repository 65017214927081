@import '~styles/margins.scss';
@import '~styles/colors.scss';
@import '~styles/fontSizes.scss';
@import '~styles/fontFamilies.scss';
@import '~styles/shadows.scss';
@import '~styles/paddings.scss';

.ObjectiveRecommendations {
  margin: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;

  .ObjectiveRecommendations-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .ObjectiveRecommendations-title {
      padding: 15px;
      font-size: 1.25rem;
    }

    .ObjectiveRecommendations-toggle-form {
      padding-right: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ObjectiveRecommendations-fields {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin: 10px;

    align-items: flex-end;

    .ObjectiveRecommendations-input {
      width: 100%;
      height: 40px;
      padding: 10px;
      background-color: #191924;
      border: solid 1px rgba(50, 50, 73, 0.42);
      color: $white;
      display: block;
      &:focus {
        outline-style: none;
      }
      &.invalid {
        border: 1px solid rgba(172, 0, 0, 0.7) !important;
      }
    }

    .ObjectiveRecommendations-input-description {
      padding: 10px;
      resize: none;
      height: 70px;
      width: 100%;
      background-color: #191924;
      border: solid 1px rgba(50, 50, 73, 0.42);
      color: $white;
      display: block;
      &:focus {
        outline-style: none;
      }
    }

    .ObjectiveRecommendations-input-buttons {
      padding-top: 10px;
      .ObjectiveRecommendations-input-button {
        margin: 5px;
        @include quickSandSemiBold;
        font-size: $fontSizeSmall;
        color: white;
        width: 70px;
        height: 20px;
        border-radius: 35px;
        border: none;
      }
      .create {
        background: $bostonBlue;
        border: 1px solid $bostonBlue;

        &:hover {
          background: $scooter;
        }

        &:disabled {
          background: #48b9d824;
          color: #ffffff5e;
        }
      }
      .update {
        background: $olivetone;

        &:hover {
          background: $greenYellow3;
        }

        &:disabled {
          background: #48b9d824;
          color: #ffffff5e;
        }
      }
      .cancel {
        background: $steelGray3;
        border: 1px solid $bostonBlue;

        &:hover {
          background: $scooter;
        }

        &:disabled {
          background: #48b9d824;
          color: #ffffff5e;
        }
      }
      .delete {
        background: $steelGray3;
        border: 1px solid rgb(172, 0, 0);

        &:hover {
          background: red;
        }

        &:disabled {
          background: #48b9d824;
          color: #ffffff5e;
        }
      }
    }
  }

  .ObjectiveRecommendations-recommendations {
    max-height: 500px;
    height: 100%;
    // width: 100%;
    margin: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    // align-content: flex-start;
    max-width: 100%;
    // padding-right: 3px;
    padding: 10px 10px 10px 10px;

    &::-webkit-scrollbar-track {
      background-color: $vulcan;
      border: 6px solid $vulcan;
      border-radius: 12px;
    }
    &::-webkit-scrollbar {
      width: 8px;
      border: 6px solid $martinique;
      border-radius: 12px;
      background: $martinique;
    }
    &::-webkit-scrollbar-thumb {
      background: $martinique;
      border: 6px solid $cinder;
      border-radius: 12px;
    }

    .ObjectiveRecommendations-input-container {
      position: sticky;
      top: 5px;
      display: flex;
      margin: 5px;
      background-color: #191924;
      border: solid 1px rgba(54, 54, 97, 0.42);
      z-index: 10;
      .input-icon {
        height: 50px;
        min-width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ObjectiveRecommendations-input-search {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        padding: 17px 16px 15px 5px;
        background-color: #191924;
        border: none;
        outline: none;

        color: $white;
        &:focus {
          outline-style: none;
        }
      }
    }

    .ObjectiveRecommendations-recommendation {
      @include quickSandSemiBold;
      display: flex;
      flex-direction: column;
      align-items: space-between;
      justify-content: center;
      margin: 5px;
      padding: 15px;
      background-color: $steelGray4;
      font-size: 14px;
      font-weight: 500;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      outline: none;
      border: none;
      color: white;

      &:hover {
        background-color: $leanit101BlueLight4;
      }
      &:focus {
        transform: scale(1.02);
        background-color: $leanit101BlueLight4;
      }
      h1 {
        color: $concret;
      }
      .disabled {
        color: $trout2;
      }

      .ObjectiveRecommendations-recommendation-header {
        display: flex;
        justify-content: space-between;
        .ObjectiveRecommendations-recommendation-name {
          position: static;
          width: fit-content;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          z-index: 1;
          font-size: 1.1rem;
          padding: 5px;
        }
      }
      .ObjectiveRecommendations-recommendation-description {
        position: static;
        width: fit-content;
        text-overflow: ellipsis;
        overflow: scroll;
        padding: 5px;
        max-height: 40px;
        z-index: 1;
      }
      .ObjectiveRecommendations-recommendation-url {
        white-space: nowrap;
        position: static;
        width: 400px;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 5px;
        z-index: 1;
        color: $leanit101Blue;
      }
      .ObjectiveRecommendations-recommendation-icon {
        color: white;
        margin: 5px;
        &:hover {
          color: $graySuit;
        }
      }
    }
  }
}
