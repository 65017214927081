@import "~styles/colors.scss";
@import "~styles/paddings.scss";
@import "~styles/margins.scss";
@import "~styles/fontSizes.scss";

.SelectTemplates-container {
  height: 100%;
  justify-content: center;
  padding: 20px;

  .SelectTemplates-formField {
    .SelectTemplates-input {
      border-bottom-color: $ebonyClay !important;
      border: 1px solid transparent;
      color: $cadetBlue;
      background-color: $steelGray4;
      display: block;
      text-align: center;
      width: 330px;
      padding: 5px;
      margin-top: 15px;

      &:focus {
        outline-style: none;
      }
    }

    label {
      margin-top: 25px;
    }

    input {
      background-color: $mirage;
    }

    textarea {
      height: 5.6rem;
      // padding-left: 13px !important;
      text-align: justify !important;
      background-color: $steelGray3;
    }
  }

  .SelectTemplates-date-label {
    color: $concret;
    font-weight: 200;
  }

  .rdrNextPrevButton {
    background-color: $vulcan;
  }

  .rdrMonthAndYearPickers {
    select {
      color: $concret;
    }
  }

  .SelectTemplates-form-group {
    select {
      width: 100%;
      border: none;
      padding: 10px;
      font-weight: 600;
      color: #f2f2f2;
      background-color: $steelGray4;

      &:focus {
        outline: none;
      }
    }
  }
  .SelectTemplates-title {
    margin: 15px 0 15px 0;
  }
  .SelectTemplates-list {
    display: flex;
    h1 {
      margin: 10px 10px 10px 0;
    }
  }
}
